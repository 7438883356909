import { useState, useEffect } from "react";
import "./NumPhotoPage.css";

import { RegisterPDFConfigObserver, UnregisterPDFConfigObserver, UpdatePDFConfig } from "interfaces/PdfInterface.js";

export default function NumPhotoPage({text}){  

  const [active, setActive] = useState(false);

  function HandleClick(){
    UpdatePDFConfig("picturesPerPage", text);
  }

  function HandleChange(value){
    if(value == text){
      setActive(true);
    }else{
      setActive(false);
    }
  }

  useEffect(()=>{
    RegisterPDFConfigObserver("picturesPerPage", HandleChange);
    
    return () => {
      UnregisterPDFConfigObserver("picturesPerPage", HandleChange);
    }
  },[]);

  return (
    <div className={`num-photo-page ${active ? "selected" : ""}`} onClick={()=>{HandleClick()}} >
      <div className={`element`}>{text}</div>
    </div>
  );
};
