import uuid from 'react-uuid';

let PDFData = [];

const PDFConfig = {
    name: "",
    baseboard: "",
    picturesPerPage: 6,
    sheetOrientation: 'portrait',
    logo: null,
    selectedPage: 0,
    summary: false,
    pdfFinished: false,
    imgCover: false,
    pdfCompression: 1.0
}
const PDFConfigObservers = {
    name: [],
    baseboard: [],
    picturesPerPage: [],
    sheetOrientation: [],
    logo: [],
    selectedPage: [],
    summary: [],
    pdfFinished: [],
    imgCover: [],
    pdfCompression: []
}

const PDFFunctions = {
    AddImage: [],
    AddTopic: [],
    UpdateData: [],
    OpenModalEditImage: [],
    OpenModalAddImage: [],
    ChangePreviewPage: [],
    DownloadPdf: [],
    UpdateImageListIndexes: [],
    ReportFinished: [],
}

function AddPageOnImages(){
    let ImageCount = 0;
    PDFData.forEach((element, index)=>{
        if(element.type === "SummaryImages"){
            ImageCount++;
            if(ImageCount > PDFConfig.picturesPerPage ){
                element.addPage = true;
                ImageCount = 1;
            }else{
                element.addPage = false;
            }
        }else{
            ImageCount = 0;
        }
    })
}

export function GetPDFConfigData(id){     
    return PDFConfig[id];
}

export function SetPDFConfigData(key, value){
    PDFConfig[key] = value;
    PDFConfigObservers[key].forEach((observer) => observer(PDFConfig[key]));    
}

export function AddNewSection(name){
    const uid = uuid();
    PDFData.push({id: uid, type: "SummaryTopic", title: name});
    UpdatePDFData(PDFData);
}

export function AddPhotoElement(data){     
    if(data.id === -1){
        UpdatePDFData([...data.images]);
    }else{
        const OldIndex = PDFData.findIndex(item => JSON.stringify(item.id).toString() === JSON.stringify(data.id).toString()); 
        console.log(OldIndex);    
        UpdatePDFData([...PDFData.slice(0, OldIndex+1), ...data.images, ...PDFData.slice(OldIndex+1)]);   
    }    
}

export function GetObjectIndexByID(id){
    const requestIndex = PDFData.findIndex(item => JSON.stringify(item.id).toString() === JSON.stringify(id).toString());     
    return requestIndex;
}

export function GetImageIndexByID(id){
    const requestIndex = PDFData.findIndex(item => JSON.stringify(item.id).toString() === JSON.stringify(id).toString());
    let imageIndex = 0;
    for(let i = 0; i < requestIndex; i++){
        if(PDFData[i].type === "SummaryImages"){
            imageIndex++;
        }
    }
    return imageIndex;
}

export function GetPhotoData(index){     
    return PDFData[index];
}

export function GetAllPhotoData(){     
    return PDFData;
}

export function GetLastIDPhotoData(){     
    if(PDFData.length === 0){
        return -1;
    }
    return PDFData[PDFData.length - 1].id;
}

export function GetPhotoDataByID(id){     
    return PDFData.find(item => JSON.stringify(item.id).toString() === JSON.stringify(id).toString());
}

export function GetNextPhotoData(index){
    if(index < PDFData.length - 1){
        if(PDFData[index+1].type === "SummaryTopic"){
            return GetNextPhotoData(index+1);
        }else{
            return PDFData[index+1];
        }
    }else{
        return -1;
    }
}

export function GetPrevPhotoData(index){
    if(index > 0){
        if(PDFData[index-1].type === "SummaryTopic"){
            return GetPrevPhotoData(index-1);
        }else{
            return PDFData[index-1];
        }
    }else{
        return -1;
    }
}

export function SetPhotoData(id, data){ 
    PDFData[id] = data;
    CallPDFFunction("UpdateData", PDFData);
}

export function SetPhotoSrc(index, src){
    PDFData[index].imgSrc = src;
    CallPDFFunction("UpdateData", PDFData);
}

export function SetPhotoDescription(index, description){
    PDFData[index].description = description;
    CallPDFFunction("UpdateData", PDFData);
}

export function DeletePhotoElement(id){ 
    UpdatePDFData([...PDFData.filter(item => item.id !== id)]);  
}

export function DeleteAllPhotoElement(){
    const data = PDFData.filter(item => item.type === "SummaryTopic");
    UpdatePDFData([...data]);
}

export function UpdatePDFData(data){
    PDFData = [...data]; 
    AddPageOnImages();
    CallPDFFunction("UpdateData", PDFData);
}

export function UpdatePhotoElement(element, index){
    console.log("Update");
    PDFData[index] = {        
        imgTitle: element.title,
        imgDescription: element.description,
        size: element.size
    };
}

export function RegisterPDFConfigObserver(key, observer){
    PDFConfigObservers[key].push(observer);
}

export function UnregisterPDFConfigObserver(key, observer){
    PDFConfigObservers[key] = PDFConfigObservers[key].filter((item) => item !== observer);
}

export function UpdatePDFConfig(key, value){
    PDFConfig[key] = value;
    PDFConfigObservers[key].forEach((observer) => observer(PDFConfig[key]));
    UpdatePDFData(PDFData);
}

export function RegisterPDFFunction(key, func){
    PDFFunctions[key].push(func);
}

export function UnregisterPDFFunction(key, func){
    PDFFunctions[key] = PDFFunctions[key].filter((item) => item !== func);
}

export function CallPDFFunction(key, data){     
    PDFFunctions[key].forEach((func) => func(data));
}

export function ResetPDFConfig(){
    PDFConfig.name = "";
    PDFConfig.baseboard = "";
    PDFConfig.picturesPerPage = 6;
    PDFConfig.sheetOrientation = 'portrait';
    PDFConfig.logo = null;
    PDFConfig.selectedPage = 0;
    PDFConfig.summary = false;
    PDFConfig.pdfFinished = false;

    PDFData = [];

    PDFConfigObservers.name.forEach((observer) => observer(PDFConfig.name));
    PDFConfigObservers.baseboard.forEach((observer) => observer(PDFConfig.baseboard));
    PDFConfigObservers.picturesPerPage.forEach((observer) => observer(PDFConfig.picturesPerPage));
    PDFConfigObservers.sheetOrientation.forEach((observer) => observer(PDFConfig.sheetOrientation));
    PDFConfigObservers.logo.forEach((observer) => observer(PDFConfig.logo));
    PDFConfigObservers.selectedPage.forEach((observer) => observer(PDFConfig.selectedPage));
    PDFConfigObservers.summary.forEach((observer) => observer(PDFConfig.summary));
    PDFConfigObservers.pdfFinished.forEach((observer) => observer(PDFConfig.pdfFinished));

    UpdatePDFData(PDFData);

}