import { useState, useEffect } from 'react';
import "./InputToggle.css";

import { RegisterPDFConfigObserver, UnregisterPDFConfigObserver, UpdatePDFConfig } from "interfaces/PdfInterface.js";

export default function InputToggle({ notePencil, arrowbend, elementKey, name, maxLength }){

  const [active, setActive] = useState(false);
  const [classState, setClassState] = useState("default");
  const [text, setText] = useState("");
  const [textLength, setTextLength] = useState(0);

  function HandleClick() {
      if (active) {
          setActive(false);
          setClassState("default");
          UpdatePDFConfig(elementKey, text);
      } else {
          setActive(true);
          setClassState("active");
      }
  }

  function HandleTextInput(event) {
    UpdatePDFConfig(elementKey, event.target.value);
  }

  function OnTextChange(text) {
      setText(text);
      setTextLength(text.length);
  }

  function handleInputKeyDown(event) {
      if (event.key === 'Enter') {
          HandleClick();
      }
  }

  useEffect(() => {
      RegisterPDFConfigObserver(elementKey, OnTextChange);

      return () => {
          UnregisterPDFConfigObserver(elementKey, OnTextChange);
      }
  }, []);

  return (
    <div className={`input-rodape property-1-${classState}`}>
      <div className="title">
        {classState === "default" && 
        <div className="default-container">
          <div className="text-wrapper">{name}</div>
          <p className="p">{text}</p>
        </div>
        }
        {classState === "active" && (
          <>
            <input  type="text" placeholder="Digite aqui" value={text} onChange={(e)=>{setText(e.target.value)}} onKeyDown={(event) => { handleInputKeyDown(event); }} maxLength={30} className="text-wrapper-2"></input >
            <div className="text-wrapper-3">{textLength}/{maxLength}</div>
          </>
        )}
      </div>
      <img
        className="note-pencil"
        alt="Note pencil"
        src={classState === "active" ? arrowbend : notePencil}
        onClick={()=>{HandleClick()}}
      />
    </div>
  );
};