import { useState, useEffect } from "react";
import { HashLink } from 'react-router-hash-link';
import { useNavigate } from "react-router-dom";

import "./MainMenu.css";

import { RegisterLayoutDataObserver, UnregisterLayoutDataObserver, GetLayoutData } from "interfaces/LayoutInterface.js";
import { RegisterUserDataObserver, UnregisterUserDataObserver, GetUserData } from "interfaces/UserInterface.js";
import { GetCurrentLanguageData, RegisterChangeLanguageObservers, UnregisterChangeLanguageObservers } from "interfaces/LocalizationInterface.js";

import Logo from "./assets/LogoMenu.png";
import HamburgerIcon from "./assets/icons8-menu.svg";
import CloseIcon from "./assets/icons8-x-50.png";
import UserIcon from "./assets/UserIcon.png";

export default function MainMenu(){

    const [showMenu, setShowMenu] = useState(GetLayoutData("showMenu"));
    const [menuOpened, setMenuOpened] = useState(false);
    const [userName, setUserName] = useState("");
    const [textLocalization, setTextLocalization] = useState(null);

    const navigate = useNavigate();

    function HandleShowMenu(){
        setShowMenu(GetLayoutData("showMenu"));
    }

    function HandleUserData(){
        setUserName(GetUserData()["username"]);
    }

    function HandleLanguageChange() {
        setTextLocalization(GetCurrentLanguageData("MainMenu"));
      }

    useEffect(() => {
        HandleLanguageChange();
        RegisterLayoutDataObserver("showMenu", HandleShowMenu);
        RegisterUserDataObserver(HandleUserData);
        window.addEventListener("resize", setMenuOpened(false));
        RegisterChangeLanguageObservers(HandleLanguageChange);
        return () => {
            UnregisterLayoutDataObserver("showMenu", HandleShowMenu);
            UnregisterUserDataObserver(HandleUserData);
            UnregisterChangeLanguageObservers(HandleLanguageChange);
        }
    }, []);

    return (        
        <div className="MainMenu" style={ showMenu ? menuOpened ? {  display: "flex", height: '100vh' } : { display: "flex" } : {display: "none"}  }>
            <div className="logoContainer">
                <img src={Logo} alt="PhotoClick Logo" />
            </div>
            <div className="buttonsContainer">                
                {/* <HashLink smooth to="/#Home_Section_1">início</HashLink>                 */}
                <HashLink smooth to="/#Home_Section_1">{textLocalization && textLocalization["HomeSection1"]}</HashLink>
                <HashLink smooth to="/#Home_Section_2">{textLocalization && textLocalization["HomeSection2"]}</HashLink>
                <HashLink smooth to="/#Home_Section_3">{textLocalization && textLocalization["HomeSection3"]}</HashLink>
            </div>
            { userName != "" ?
            <div className="loginContainer" onClick={()=>{navigate("/account")}}>                
                <p className="username">{userName}</p>
                <img className="usericon" src={UserIcon} alt="" />                 
            </div>
            :
            <div className="loginContainer">
                <p className="btLogin" onClick={()=>{navigate("/login")}}>{textLocalization && textLocalization["ButtonLogin"]}</p>
                <div className="btRegister">
                    <p onClick={()=>{navigate("/register")}}>{textLocalization && textLocalization["ButtonRegister"]}</p>
                </div>
            </div>
            }
            <div className="mobileButtonsContainer">
                <img src={CloseIcon} alt="Close Menu" onClick={()=>{setMenuOpened(false)}} style={ menuOpened ? {display: "flex", width: "24px", height: "24px" } : {display: "none"} } />
                <img src={HamburgerIcon} alt="Open Menu" onClick={()=>{setMenuOpened(true)}} style={ menuOpened ? {display: "none" } : {display: "flex", width: "30px", height: "30px"} } />
                
            </div>
            <div className="buttonsMobileContainer" style={ menuOpened ? {display: "flex"} : {display: "none"} }>                
                    {/* <HashLink smooth to="/#Home_Section_1" onClick={()=>{setMenuOpened(false)}}>início</HashLink>                 */}
                    <HashLink smooth to="/#Home_Section_2" onClick={()=>{setMenuOpened(false)}}>{textLocalization && textLocalization["HomeSection1"]}</HashLink>
                    <HashLink smooth to="/#Home_Section_3" onClick={()=>{setMenuOpened(false)}}>{textLocalization && textLocalization["HomeSection2"]}</HashLink>
                    <p onClick={()=>{setMenuOpened(false); navigate("/account")}}>{textLocalization && textLocalization["HomeSection3"]}</p>
                </div>
                <div className="loginMobileContainer" style={ menuOpened ? {display: "flex"} : {display: "none"} }>
                    <p className="btLogin" onClick={()=>{setMenuOpened(false); navigate("/login")}}>{textLocalization && textLocalization["ButtonLogin"]}</p>
                    <div className="btRegister" onClick={()=>{setMenuOpened(false);navigate("/register")}}>
                        <p>{textLocalization && textLocalization["ButtonRegister"]}</p>
                    </div>   
                </div>
        </div>       
    );
}