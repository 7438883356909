import "./SummaryPage.css";

export default function SummaryPage(props){
  
  return (
    <div className="SummaryPage">
      <div className="text-wrapper">Página 01</div>
      <div className="rectangle" />
      <div className="div">01</div>
    </div>
  );
};