import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword,setPersistence,  browserSessionPersistence, sendEmailVerification   } from "firebase/auth";
import { getDatabase, ref, set, update, onValue  } from "firebase/database";

import { SetUserData } from "interfaces/UserInterface.js";
import { SetPDFConfigData } from "interfaces/PdfInterface.js";

import Axios from "axios";
// import { pdf } from "@react-pdf/renderer";


const firebaseConfig = {
    // apiKey: process.env.REACT_APP_API_KEY,
    // authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    // databaseURL: process.env.REACT_APP_DATABASE_URL,
    // projectId: process.env.REACT_APP_PROJECT_ID,
    // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    // messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    // appId: process.env.REACT_APP_APPID,
    // measurementId: process.env.REACT_APP_MEASUREMENT_ID

    // old
    // apiKey: "AIzaSyA0nTneL7vB-0eP4g4KT_zWXiDkHe6yobU",
    // authDomain: "photoclick-test.firebaseapp.com",
    // databaseURL: "https://photoclick-test-default-rtdb.firebaseio.com",
    // projectId: "photoclick-test",
    // storageBucket: "photoclick-test.appspot.com",
    // messagingSenderId: "795395966330",
    // appId: "1:795395966330:web:b76cd5a1613998f3be42c3"

    //new
    apiKey: "AIzaSyB4a1sulFaxvIoezHICWaEuMDYtMUH3wbI",
    authDomain: "photoclick-3057f.firebaseapp.com",
    databaseURL: "https://photoclick-3057f-default-rtdb.firebaseio.com",
    projectId: "photoclick-3057f",
    storageBucket: "photoclick-3057f.appspot.com",
    messagingSenderId: "486084622237",
    appId: "1:486084622237:web:7648775cec72e94dac2174",
    measurementId: "G-8XT0YM3FVL"

};
  
const FirebaseData = {
    app: null,
    analytics: null,
    auth: null,
    database: null,
    user: null,
    authStateUpdated: false,
    pdfs: null
};

export function InitializeFirebase(){
    FirebaseData['app'] = initializeApp(firebaseConfig);
    // FirebaseData['analytics'] = getAnalytics(FirebaseData['app']);
    FirebaseData['auth'] = getAuth();
    FirebaseData['database'] = getDatabase(FirebaseData['app']);  
    
    setPersistence(FirebaseData['auth'], browserSessionPersistence)
    .then(() => {  
        
    })
    .catch((error) => {
        console.log("Error de conexão com o servidor");
    });
   
    
    // Auth state changes
    onAuthStateChanged(FirebaseData['auth'], (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            // const uid = user.uid;
            // console.log(uid);
            FirebaseData['user'] = user;
            FirebaseData['authStateUpdated'] = true;

            onValue(ref(FirebaseData['database'], 'users/' + FirebaseData['user'].uid), (snapshot) => {
                // console.log(snapshot.val());
                SetUserData({"username": snapshot.val().name});
            }
            , {    
                onlyOnce: true
            });

            onValue(ref(FirebaseData['database'], 'config/'), (snapshot) => {
                // console.log(snapshot.val());
                SetPDFConfigData("pdfCompression", snapshot.val().pdfCompression);
                console.log(snapshot.val().pdfCompression);
            }
            , {    
                onlyOnce: true
            });
            // ...
        } else {
            FirebaseData['authStateUpdated'] = true;
            // User is signed out
            // ...
        }
    });
}

export function GetUID(){
    if(FirebaseData['user'] == null){
        return null;
    }
    return FirebaseData['user'].uid;
}

export function IsEmailVerified(SuccessFunc, ErrorFunc){
    if(FirebaseData['user'] == null){
        // console.log("Usuário não logado");
        const WaitInterval = setInterval(() => {
            if(FirebaseData['authStateUpdated'] === true){
                const userCredential = getAuth().currentUser;
                // console.log(userCredential)
                if(userCredential == null){ 
                    ErrorFunc();
                } else {
                    FirebaseData['user'] = userCredential;
                    FirebaseData['user'].reload()
                    .then(() => {
                        // console.log(FirebaseData['user'].emailVerified);
                        // console.log(FirebaseData['user']);
                        if(FirebaseData['user'].emailVerified === false){
                            ErrorFunc();
                        }else{
                            SuccessFunc();
                        }
                    });
                }
                clearInterval(WaitInterval);
            }
        }
        , 100);
        
        
    } else {
        FirebaseData['user'].reload()
        .then(() => {
            // console.log(FirebaseData['user'].emailVerified);
            // console.log(FirebaseData['user']);
            // console.log(FirebaseData['user'].uid);
            onValue(ref(FirebaseData['database'], 'pdfs/'+FirebaseData['user'].uid), (snapshot) => {
                // console.log(snapshot.val());
            }, {    
                onlyOnce: true
            });


            if(FirebaseData['user'].emailVerified === false){
                ErrorFunc();
            }else{
                SuccessFunc();
            }
        });
    }
}

export function CreateUser(data, callbackSuccess, callbackError){

    let timeCreate = new Date();

    let dayCreate = timeCreate.getDate();
    dayCreate = dayCreate.toString();
    if(dayCreate.length === 1){
        dayCreate = "0" + dayCreate;
    }
    
    let monthCreate = timeCreate.getMonth() + 1;
    monthCreate = monthCreate.toString();
    if(monthCreate.length === 1){
        monthCreate = "0" + monthCreate;
    }
    
    let yearCreate = timeCreate.getFullYear();
    
    let hourCreate = timeCreate.getHours();
    hourCreate = hourCreate.toString();
    if(hourCreate.length === 1){
        hourCreate = "0" + hourCreate;
    }
    
    let minutesCreate = timeCreate.getMinutes();
    minutesCreate = minutesCreate.toString();
    if(minutesCreate.length === 1){
        minutesCreate = "0" + minutesCreate;
    }
    
    let secondsCreate = timeCreate.getSeconds();
    secondsCreate = secondsCreate.toString();
    if(secondsCreate.length === 1){
        secondsCreate = "0" + secondsCreate;
    }
    
    let momentCreate = yearCreate + "-" + monthCreate + "-" + dayCreate + " " + hourCreate + ":" + minutesCreate + ":" + secondsCreate;  

    setPersistence(FirebaseData['auth'], browserSessionPersistence)
    .then(() => {  
        createUserWithEmailAndPassword(FirebaseData['auth'], data.email, data.password)
        .then((userCredential) => {        
            FirebaseData['user'] = userCredential.user;
            console.log(userCredential.user);

            set(ref(FirebaseData['database'], 'users/' + FirebaseData['user'].uid), {
                name: data.name,
                email: data.email,                
                country: data.country,
                region: data.region,
                city: data.city, 
                ip: data.ip,
                lat: data.lat,
                lon: data.lon,             
                accept: data.accept,
                criacao_cadastro: momentCreate,
                ultimo_acesso: momentCreate                
            })
            .then(() => {       
                const actionCodeSettings = getAuth().currentUser;
                sendEmailVerification(actionCodeSettings)
                .then(() => {
                    
                })
                .catch((error) => {
                    console.log(error.message);
                });            
                callbackSuccess();
            })
            .catch((error) => {
                // console.error(error.message);
                callbackError("Error de conexão com o servidor");                
            });
        })
        .catch((error) => {
            if (error.code === "auth/email-already-in-use") {
                callbackError("Usuário já cadastrado");
            }else{
                callbackError("Error de conexão com o servidor");
            }
            // const errorCode = error.code;
            // const errorMessage = error.message;
        }); 
    })
    .catch((error) => {
        console.log("Error de conexão com o servidor");
    });    
}

export function SignInUser(data, callbackSuccess, callbackError, EmailNotVerified){    
    setPersistence(FirebaseData['auth'], browserSessionPersistence)
    .then(() => {
        signInWithEmailAndPassword(FirebaseData['auth'], data.email, data.password)
        .then((userCredential) => {        
            const user = userCredential.user;
            FirebaseData['user'] = user;
            
            if(user.emailVerified === false){
                
                const actionCodeSettings = getAuth().currentUser;
                sendEmailVerification(actionCodeSettings)
                .then(() => {
                    EmailNotVerified();
                })
                .catch((error) => {
                    console.log(error.message);
                });               
                
                return;
            }

            let timeCreate = new Date();

            let dayCreate = timeCreate.getDate();
            dayCreate = dayCreate.toString();
            if(dayCreate.length === 1){
                dayCreate = "0" + dayCreate;
            }
            
            let monthCreate = timeCreate.getMonth() + 1;
            monthCreate = monthCreate.toString();
            if(monthCreate.length === 1){
                monthCreate = "0" + monthCreate;
            }
            
            let yearCreate = timeCreate.getFullYear();
            
            let hourCreate = timeCreate.getHours();
            hourCreate = hourCreate.toString();
            if(hourCreate.length === 1){
                hourCreate = "0" + hourCreate;
            }
            
            let minutesCreate = timeCreate.getMinutes();
            minutesCreate = minutesCreate.toString();
            if(minutesCreate.length === 1){
                minutesCreate = "0" + minutesCreate;
            }
            
            let secondsCreate = timeCreate.getSeconds();
            secondsCreate = secondsCreate.toString();
            if(secondsCreate.length === 1){
                secondsCreate = "0" + secondsCreate;
            }
            
            let momentCreate = yearCreate + "-" + monthCreate + "-" + dayCreate + " " + hourCreate + ":" + minutesCreate + ":" + secondsCreate;

            update(ref(FirebaseData['database'], 'users/' + FirebaseData['user'].uid), {
                ultimo_acesso: momentCreate
            })
            .then((data) => {      
                callbackSuccess();
            });
            
        })
        .catch((error) => {
            // console.log(error.code);
            if (error.code === "auth/invalid-login-credentials") {
                callbackError("Dados incorretos");
            }else if(error.code === "auth/user-not-found"){
                callbackError("Usuário não cadastrado");
            }else if (error.code === "auth/too-many-requests") {
                callbackError("Muitas tentativas de login. Tente novamente mais tarde");
            }else{
                callbackError("Error de conexão com o servidor");
            }
        });
    })
    .catch((error) => {
        console.log("Error de conexão com o servidor");
    }); 
}

export function GetPDFData(callbackSuccess, callbackError){

    if(FirebaseData['user'] == null){
        // console.log("Usuário não logado");
        callbackError();
        return;
    }

    onValue(ref(FirebaseData['database'], 'pdfs/'+ FirebaseData["user"].uid), (snapshot) => {   
        if(snapshot.val() != null){
            const pdfs = [];
            Object.keys(snapshot.val()).forEach((key)=>{
                pdfs.push({
                    id: key,
                    title: snapshot.val()[key].title,                
                    url: snapshot.val()[key].url,
                    timestamp: snapshot.val()[key].timestamp,
                    deleted: snapshot.val()[key].deleted || false
                });
            });
            FirebaseData['pdfs'] = pdfs;
            callbackSuccess(FirebaseData['pdfs']);
        }else{           
            callbackSuccess([]);
        }
    }, {    
        onlyOnce: true
    });

}

export function DeletePDFData(id, callbackSuccess, callbackError){
    if(FirebaseData['user'] == null){
        // console.log("Usuário não logado");
        callbackError();
        return;
    }
    let params = new URLSearchParams();
    params.append('id', id);
    params.append('uid', FirebaseData['user'].uid);

    Axios.post("https://validation.photoclick.com.br/delete_pdf.php", params)
        .then(response => {
            console.log(response);

            // Set deleted true on element with id using filter
            FirebaseData['pdfs'].forEach((element, index) => {
                if(element.id === id){
                    FirebaseData['pdfs'][index].deleted = true;
                }
            });


            // console.log(FirebaseData['pdfs']);
            
            callbackSuccess(FirebaseData['pdfs']);
        });

    // update(ref(FirebaseData['database'], 'pdfs/'+ FirebaseData["user"].uid + "/" + id), {
    //     deleted: true
    // })
    // .then((data) => {      
    //     FirebaseData['pdfs'][id].deleted = true;      
    //     callbackSuccess(FirebaseData['pdfs']);
    // });
}