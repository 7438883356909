import { useState, useEffect, useRef } from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import "./ImageOrderCard.css";

export default function ImageOrderCard(props){
    
    const [imageSrc, setImageSrc] = useState(null);
    const [imageWidth, setImageWidth] = useState("0px");
    const [imageHeight, setImageHeight] = useState("0px");

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: props.id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }

    useEffect(()=>{
        const reader  = new FileReader();
        reader.onloadend = function () {
            setImageSrc(reader.result); 
            let image = new Image();  
            image.onload = function() {
                if(image.width > image.height){
                    setImageWidth("176px");
                    setImageHeight("auto");
                }else{
                    setImageWidth("auto");
                    setImageHeight("176px");
                }              
            }; 
            image.src = reader.result;
        }
        reader.readAsDataURL(props.image);
    }, []);

    return (
        <div className="ImageOrderCard" ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <div className="ImageContainer">                
                <img src={imageSrc} alt="" style={{width: imageWidth, height: imageHeight}}></img>
            </div>
            <div className="DeleteContainer"  >
                <img src="/images/Trash.png" alt="" className="DeleteIcon" onClick={()=>{props.deleteFunction(props.id)}} />
            </div>
        </div>
    )
}