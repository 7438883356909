import { useState, useEffect, useRef } from "react";
import uuid from 'react-uuid';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import "./PagePreview.css";

import PagePreviewItem from "components/PagePreviewItem";

import { RegisterPDFFunction, UnregisterPDFFunction, RegisterPDFConfigObserver, UnregisterPDFConfigObserver } from "interfaces/PdfInterface.js";

export default function PagePreview(props) {

    const [selectedPageContent, setSelectedPageContent] = useState([]);
    const [baseboardText, setBaseboardText] = useState("");
    const [pageOrientation, setPageOrientation] = useState("");
    const [logo, setLogo] = useState(null);
    const [imgCover, setImgCover] = useState(false);

    const pdfRef = useRef();

    function PreviewPageChange(data){
      // console.log(data);
        setSelectedPageContent([...data]);
    }

    function HandleSheetOrientation(value){
      if(value == "portrait"){
          setPageOrientation("");
      }else if(value == "landscape"){
          setPageOrientation("-landscape");
      }
    }

    function HandleLogoChange(src){
      setLogo(src);
    }

    useEffect(()=>{  
        RegisterPDFFunction("ChangePreviewPage", PreviewPageChange);
        RegisterPDFConfigObserver("baseboard", setBaseboardText);
        RegisterPDFConfigObserver("sheetOrientation", HandleSheetOrientation);
        RegisterPDFConfigObserver("logo", HandleLogoChange);
        RegisterPDFConfigObserver("imgCover", setImgCover);

        return () => {
            UnregisterPDFFunction("ChangePreviewPage", PreviewPageChange);
            UnregisterPDFConfigObserver("baseboard", setBaseboardText);
            UnregisterPDFConfigObserver("sheetOrientation", HandleSheetOrientation);
            UnregisterPDFConfigObserver("logo", HandleLogoChange);
            UnregisterPDFConfigObserver("imgCover", setImgCover);
        }
    },[]);

    return (
      <div className={`page-preview ${pageOrientation == "-landscape" ? "page-preview-landscape" : ""}`} id="page-preview" ref={pdfRef}>
        <div className={`relatorio-x${pageOrientation}`}>
          <header className="header">
            <img className="logo" alt="" src={ logo != null ? logo : props.logo} />
            <div className="text-wrapper">{props.title}</div>
          </header>
          <div className={`list-photos-preview${pageOrientation}`}>
            {              
              selectedPageContent.map((item, index) => (              
              <PagePreviewItem key={uuid()} img={item.img} description={item.description} id={item.id} imagesCount={selectedPageContent.length} imgCover={imgCover} />) 
              )           
            }
          </div>
          <div className="frame-rodape">
            <p className="div">{baseboardText}</p>
            <div className="div">{props.pagenumber}</div>
          </div>         
        </div>
      </div>
    );
}
