import { useState, useEffect } from "react";

import { RegisterLayoutDataObserver, UnregisterLayoutDataObserver, GetLayoutData } from "interfaces/LayoutInterface.js";

export default function MenuSpacer(){

    const [showMenu, setShowMenu] = useState(GetLayoutData("showMenu"));

    function HandleShowMenu(){
        setShowMenu(GetLayoutData("showMenu"));
    }

    useEffect(() => {
        RegisterLayoutDataObserver("showMenu", HandleShowMenu);
        return () => {
            UnregisterLayoutDataObserver("showMenu", HandleShowMenu);
        }
    }, []);

    return (
        <div style={ showMenu? { width: '100%', height: '50px', display: "flex" } : { display: "none" }}></div>
    );
}