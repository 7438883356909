import { useState, useEffect, useRef  } from "react";
import uuid from 'react-uuid';
import html2canvas from "html2canvas";
import domtoimage from 'dom-to-image';
import jsPDF from "jspdf";
import Axios from "axios";

import "./PagePDF.css";

import PagePreviewItem from "components/PagePreviewItem";

import { RegisterPDFFunction, UnregisterPDFFunction, RegisterPDFConfigObserver, UnregisterPDFConfigObserver, GetAllPhotoData, GetPDFConfigData, CallPDFFunction, UpdatePDFConfig } from "interfaces/PdfInterface.js";
import { GetUID } from "services/Database/Firebase.js";
import { SetLayoutData } from "interfaces/LayoutInterface.js";

export default function PagePDF(props) {

    const [baseboardText, setBaseboardText] = useState("");
    const [pageOrientation, setPageOrientation] = useState("");
    const [logo, setLogo] = useState(null);
    const [pdfData, setPdfData] = useState([]);
    const [pdfSummary, setPdfSummary] = useState([[]]);
    const [pdfHasSummary, setPdfHasSummary] = useState(false);
    const [imgCover, setImgCover] = useState(false);

    const pdfRef = useRef();

    let countPages = 0;
    const pdfImages = {};

    function HandleSheetOrientation(value){
      if(value === "portrait"){
          setPageOrientation("");
      }else if(value === "landscape"){
          setPageOrientation("-landscape");
      }
    }

    function HandleLogoChange(src){
      setLogo(src);
    }

    function HandlePdfData(data){
        
    }

    function AddCountPages(){
        countPages++;
    }

    function VerifyCountPages(){  
        return countPages < pdfRef.current.children.length - 1;
    }

    function AddImageToPDF(img, index){     
        pdfImages[index] = img;
        const keysArray = Object.keys(pdfImages);         
        if(keysArray.length === pdfRef.current.children.length){
            AddPDFPages();
        }
    }

    function AddPDFPages() {

        const PDFCompression = GetPDFConfigData("pdfCompression");

        const pdf = pageOrientation === "-landscape" ?  new jsPDF("l", "px", [1684 * PDFCompression, 1190 * PDFCompression], true) : new jsPDF("p", "px", [1190 * PDFCompression, 1684 * PDFCompression], true);

        for(let i = 0; i < pdfRef.current.children.length; i++){
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();            
            pdf.addImage(pdfImages[i], "PNG", 0, 0, pdfWidth - 1, pdfHeight - 1, "", "FAST");
            if (VerifyCountPages()) {
                pdf.addPage();
                AddCountPages();
            } else {
                setTimeout(() => {                    
                    const PDFData = {
                        pdf: pdf
                    }
                    SavePDF(PDFData);
                }, 500);
            }
        }

    }

    function SavePDF({pdf}){   
        const inrtv = setInterval(() => {
            if(countPages === pdfRef.current.children.length - 1){                  
                //pdf.output('save', "test.pdf");

                const pdfName = GetPDFConfigData("name");
                const uid = GetUID();
                const pdfOutput = pdf.output('datauristring');
                let params = new URLSearchParams();
                params.append('title', pdfName);
                params.append('uid', uid);
                params.append('pdf', pdfOutput.split('base64,')[1]);
                Axios.post("https://validation.photoclick.com.br/register_pdf.php", params)
                .then(response => {
                    console.log(response);
                    CallPDFFunction("ReportFinished", {link :response.data});  
                    UpdatePDFConfig("pdfFinished", true);   
                    SetLayoutData("loadingScreen", false);                               
                });

                clearInterval(inrtv);
            }
        }, 500);
    }


    function GeneratePDF(){
      const data = GetAllPhotoData();
      const imagePerPage = GetPDFConfigData("picturesPerPage");
        let imagePerPageCount = 0;
        let PageCount = 0;
        let lastType = "";
        let PagesSetup = [];
        let imageCount = 0;
        let imageIdByIndex = {};
        let summary = [[]];
        let summaryPageIndex = 0;
        if(data.length === 0){
            return;
        }
        SetLayoutData("loadingScreen", true);
        if(data[0].type === "SummaryTopic"){
            PagesSetup.push([0]);
            lastType = "SummaryTopic";
       }else if(data[0].type === "SummaryImages"){
            PagesSetup.push([0]);
            lastType = "SummaryImages";
            imagePerPageCount++;
            imageCount++;
            console.log(data[0].id);
            imageIdByIndex[data[0].id] = imageCount
       }
        for(let i = 1; i < data.length; i++){
           if(data[i].type === "SummaryTopic"){
                PageCount++;
                PagesSetup.push([i]);
                lastType = "SummaryTopic";
                imagePerPageCount = 0;
           }else if(data[i].type === "SummaryImages"){
                imagePerPageCount++;
                imageCount++;
                imageIdByIndex[data[i].id] = imageCount;
                if(imagePerPageCount > imagePerPage){
                    imagePerPageCount = 1;
                    PageCount++;
                    PagesSetup.push([i]);
                }else{
                    PagesSetup[parseInt(PageCount)].push(i);
                }
                lastType = "SummaryImages";                
           }       
        }

        const PrintPDFData = [];

        let pageTitle = "";
        PagesSetup.forEach((element, index) => {
            const ImageData = [];
            element.forEach((element2, index2) => {
                if(data[element2].type === "SummaryImages"){                    
                    ImageData.push({
                        id: data[element2].id,
                        img: data[element2].imgSrc,
                        title: "",
                        description: data[element2].description
                    });
                }else if(data[element2].type === "SummaryTopic"){
                    pageTitle = data[element2].title;
                    if(summaryPageIndex === 0){
                        if(summary[summaryPageIndex].length >= 18){
                            summaryPageIndex++;
                            summary.push([]);
                        }
                    }else{
                        if(summary[summaryPageIndex].length >= 20){
                            summaryPageIndex++;
                            summary.push([]);
                        }
                    }
                    summary[summaryPageIndex].push({
                        title: data[element2].title,
                        page: index + 1
                    });
                }
            });
            PrintPDFData.push({
                title: pageTitle,
                images: ImageData
            });
        });
        setPdfSummary([...summary]);
        setPdfData([...PrintPDFData]);   
        
        setPdfHasSummary(GetPDFConfigData("summary"));
        

    }    

    useEffect(()=>{  
        RegisterPDFFunction("UpdateData", HandlePdfData);
        RegisterPDFConfigObserver("baseboard", setBaseboardText);
        RegisterPDFConfigObserver("sheetOrientation", HandleSheetOrientation);
        RegisterPDFConfigObserver("logo", HandleLogoChange);
        RegisterPDFFunction("DownloadPdf", GeneratePDF);
        RegisterPDFConfigObserver("imgCover", setImgCover);

        return () => {
            UnregisterPDFFunction("UpdateData", HandlePdfData);
            UnregisterPDFConfigObserver("baseboard", setBaseboardText);
            UnregisterPDFConfigObserver("sheetOrientation", HandleSheetOrientation);
            UnregisterPDFConfigObserver("logo", HandleLogoChange);
            UnregisterPDFFunction("DownloadPdf", GeneratePDF);
            UnregisterPDFConfigObserver("imgCover", setImgCover);
        }
    },[]);

    useEffect(()=>{
        setTimeout(() => {
            if(pdfData !== undefined){
                if(pdfData.length > 0){  
                    for(let i = 0; i < pdfRef.current.children.length; i++){
                        if(pdfRef.current.children[i] != null){                          
                            domtoimage.toPng(pdfRef.current.children[i])
                            .then(function (dataUrl) {
                                AddImageToPDF(dataUrl, i);                              
                            })
                            .catch(function (error) {
                                console.error(error);
                            });
                        }
                    }  
                    
                }
            }
        }, 200);
        
    },[pdfData]);

    return (
      <div 
        style={{display: 'flex', flexDirection: 'column', 
            position: 'fixed', top: 0, left: 0, zIndex: -1
        }} 
        ref={pdfRef}
        id="pdfRef"
        className="pdfBuilder"
      > 
        { pdfHasSummary && pdfSummary[0] != [] && pdfSummary.map((PDFSummaryElement, index)=>{
            return <div key={index} className={`page-pdf ${pageOrientation === "-landscape" ? "page-pdf-landscape" : ""}`} style={pageOrientation === "-landscape" ? {width: "1684px", height: "1190px", pageBreakAfter: 'always'} :  {width: "1190px", height: "1684px", pageBreakAfter: 'always'}} >
            <div className={`relatorio-x${pageOrientation}`}>
            <header className="header">
                <img className="logo" alt="" src={ logo != null ? logo : props.logo} style={{height: "46px"}} />
                <div className="text-wrapper"></div>
            </header>
            <div className={`summaryContainer${pageOrientation}`}>
                {index === 0 ? 
                <div className="summaryTitle">
                    <p>Sumário</p>
                </div>
                : null}

                {PDFSummaryElement.map((SummaryLineElement, index2)=>{
                    return <div key={index2} className="summaryLine">
                        <div className="summaryLineName">{SummaryLineElement.title}</div>
                        <div className="summaryLineDots" />
                        <div className="summaryLineNumber">{SummaryLineElement.page}</div>
                    </div>
                })}                
                
            </div>
            <div className="frame-rodape">
                <p className="div" style={{fontSize: "24px"}}>{baseboardText}</p>
                <div className="div"></div>
            </div>         
            </div>
        </div>
        })}
        {pdfData.map((PDFElement, index)=>{
          return <div key={index} className={`page-pdf ${pageOrientation === "-landscape" ? "page-pdf-landscape" : ""}`} style={pageOrientation === "-landscape" ? {width: "1684px", height: "1190px", pageBreakAfter: 'always'} :  {width: "1190px", height: "1684px", pageBreakAfter: 'always'}} >
            <div className={`relatorio-x${pageOrientation}`}>
              <header className="header">
                <img className="logo" alt="" src={ logo != null ? logo : props.logo} style={{height: "46px"}}  />
                <div className="text-wrapper" style={{fontSize: "24px"}}>{PDFElement.title}</div>
              </header>
              <div className={`list-photos-preview${pageOrientation}`}>
                {
                  PDFElement.images.map((item, index) => <PagePreviewItem key={uuid()} img={item.img} description={item.description} id={item.id} imagesCount={PDFElement.images.length} renderPDF={true} imgCover={imgCover} />)                  
                }
              </div>
              <div className="frame-rodape">
                <p className="div" style={{fontSize: "24px"}}>{baseboardText}</p>
                <div className="div" style={{fontSize: "24px"}}>{index + 1}</div>
              </div>         
            </div>
          </div>
        })}
      </div>
    );
}
