import React from 'react';

import './About.css';

export default function About(){
    return (
        <div className='about'>
                <div className='container'>
                <h1 className='about-title'>About</h1>
                <p className='about-description'>This is the About page.</p>
                </div>
        </div>
    );
};
