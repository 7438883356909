import { useState } from "react";

import CardPrice from "components/CardPrice";
import "./style.css";

import cardPriceCheck from "./assets/check-1.svg";

export default function Princing({
  property1,
  className = "",  
  cardPriceText = "Text description",  
  cardPriceUsersThree = "/img/usersthree-2.svg",  
  texts
}) {

  const [anualPlan, setAnualPlan] = useState(false);
  


  return (
    <div className={`princing ${className}`} id="Home_Section_2">
      <div className="tabmenu-price">
        <div
          className={`mensal ${anualPlan ? "" : "variant-2"}`}
          onClick={() => {
            setAnualPlan(false);
          }}
        >
          <div className="text-wrapper-2">
            {texts && texts["PlanPeriod1"]}
          </div>
        </div>
        <div
          className={`anual ${anualPlan ? "variant-2" : ""}`}
          onClick={() => {
            setAnualPlan(true);
          }}
        >
          <div className="text-wrapper-3">
            {texts && texts["PlanPeriod2"]}
          </div>
        </div>
      </div>
      <div className="list-price">
        {/* Add ref to each CardPrice div */}
        <CardPrice
          btnText="Comece agora"
          check={cardPriceCheck}
          className="card-price-instance"
          hasDiv={false}
          hasUsersThree={false}
          img={cardPriceCheck}
          text={cardPriceText}
          text1={texts && texts["Plan1"]["Title"]}
          text2={texts && texts["Plan1"]["Price"]}          
        />
        <CardPrice
          btnText="Experimente grátis"
          check={cardPriceCheck}
          className="card-price-3"
          divClassName="instance-node"
          divClassName1="instance-node"
          divClassName2="instance-node"
          divClassName3="instance-node"
          divClassName4="instance-node"
          divClassNameOverride="instance-node"
          hasDiv={property1 === "default" ? false : undefined}
          hasUsersThree={false}
          img={cardPriceCheck}
          rectangleClassName="card-price-2"
          text="Text description"
          text1="Básico"
          text2={property1 === "variant-2" ? "R$10" : "R$15"}
        />
        <CardPrice
          btnText="Experimente grátis"
          check={cardPriceCheck}
          className="card-price-3"
          divClassName="instance-node"
          divClassName1="instance-node"
          divClassName2="instance-node"
          divClassName3="instance-node"
          divClassName4="instance-node"
          divClassNameOverride="instance-node"
          hasDiv={property1 === "default" ? false : undefined}
          hasUsersThree={false}
          img={cardPriceCheck}
          rectangleClassName="card-price-2"
          text="Text description"
          text1="Pro"
          text2={property1 === "variant-2" ? "R$10" : "R$15"}
        />
        <CardPrice
          btnText="Entre em contato"
          check={cardPriceCheck}
          className="card-price-instance"
          hasDiv={false}
          hasPrice={false}
          img={cardPriceCheck}
          text="Text description"
          text1="Enterprise"
          usersThree={cardPriceUsersThree}
        />
      </div>
    </div>
  );
};