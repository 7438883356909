const LocalizationData = {
    currentLanguage: "",
    currentLanguageData: {

    },
    allLanguages: {

    }
}

let LocalizationDataObservers = [];

export function GetCurrentLanguageName(){
    return LocalizationData.currentLanguage;
}

export function GetCurrentLanguageData(section){
    return LocalizationData.currentLanguageData[section];
}

export function SetCurrentLanguage(newLanguage) {
    LocalizationData.currentLanguage =  newLanguage;
    LocalizationData.currentLanguageData =  LocalizationData.allLanguages[newLanguage].data;
    ChangeLanguageNotify();
}

export function SetInitialLanguagesData(data, initialName){
    LocalizationData.allLanguages = data;
    LocalizationData.currentLanguage =  initialName;
    LocalizationData.currentLanguageData =  data[initialName];
}

export function RegisterChangeLanguageObservers(func){
    LocalizationDataObservers.push(func);
}

export function UnregisterChangeLanguageObservers(func){
    LocalizationDataObservers = LocalizationDataObservers.filter((item) => item !== func);
}

export function ChangeLanguageNotify(){
    LocalizationDataObservers.forEach((element, index) => {
        element();
    }); 
}

