import React from 'react';

import './Tutorial.css';

export default function Tutorial() {
    return (
        <div className='tutorial'>
            <div className='container'>
                <h1 className='tutorial-title'>Tutorial</h1>
                <p className='tutorial-description'>This is the Tutorial page.</p>
                <div className='videoPlayerContainer'>
                    <div className='videoPlayer'>
                        <iframe
                            src='https://www.youtube.com/embed/PvnlpPWAcZc'
                            title='Tutorial Video 1'
                            allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div className='videoPlayer'>
                        <iframe
                            src='https://www.youtube.com/embed/PvnlpPWAcZc'
                            title='Tutorial Video 1'
                            allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div className='videoPlayer'>
                        <iframe
                            src='https://www.youtube.com/embed/PvnlpPWAcZc'
                            title='Tutorial Video 1'
                            allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}
