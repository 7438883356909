import { useEffect, useRef, useState } from "react";
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';

import "./ImageUpload.css";

import ImageOrderCard from "components/ImageOrderCard";
// import { AddPhotoSrc } from "interfaces/PdfInterface.js";

export default function ImageUpload(){

    const [draggableImages, setDraggableImages] = useState([]);  

    const UploadImagesInput = useRef();
    const DivForm = useRef();
    const UploadImagesForm = useRef();

    const sensors = useSensors(
        useSensor(PointerSensor, {
          activationConstraint: {
            distance: 8,
          },
        })
    );

    function HandleUploadClick(event){
        event.preventDefault();
        UploadImagesInput.current.click();
    }

    function HandleNextClick(event){
        event.preventDefault();
        draggableImages.forEach(element => {
            // AddPhotoSrc(element);
        });

    }

    function handleOnDragEndDnd(result){        
        if(result.active.id !== result.over.id){
            const OldIndex = draggableImages.findIndex(item => item.id === result.active.id);
            const NewIndex = draggableImages.findIndex(item => item.id === result.over.id);  

            setDraggableImages(arrayMove(draggableImages, OldIndex, NewIndex));
        }
    }

    function DeleteImage(id){
        setDraggableImages(draggableImages.filter(item => item.id !== id))
    }

    useEffect(()=>{
        UploadImagesInput.current.onchange = ()=>{        
            for(let i=0; i<UploadImagesInput.current.files.length; i++){  
                const time = Date.now();
                setDraggableImages(prevState =>[...prevState, {
                    id: i + time,
                    imgSrc: UploadImagesInput.current.files[i]
                }]);  
            }          
        };        
    }, []);
    
    return (
        <div className="ImageUpload">
            <div ref={DivForm}>
                <form ref={UploadImagesForm}>
                    <button onClick={(e)=>{HandleUploadClick(e)}}>CARREGAR FOTOS</button>
                    <input type="file" name="my_file[]" ref={UploadImagesInput} multiple></input>                
                </form>
            </div>

            <div>
                <DndContext
                    collisionDetection={closestCenter}
                    onDragEnd={handleOnDragEndDnd}
                    sensors={sensors}
                >
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', gap: '12px', touchAction: 'none'}}>
                        <SortableContext
                            items={draggableImages.map(item => item.id)}
                            strategy={rectSortingStrategy}                            
                        >
                            {draggableImages.map((element, index) => <ImageOrderCard key={element.id} id={element.id} image={element.imgSrc} deleteFunction={DeleteImage}  />)}
                        </SortableContext>
                    </div>
                </DndContext>
            </div>
            <div className="btNextContainer">
                {  draggableImages.length > 0 ?
                    <button onClick={(e)=>{HandleNextClick(e)}}>PRÓXIMO</button>
                    : null
                }
            </div>
        </div>
    );
}