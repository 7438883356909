import { useState, useEffect } from "react";

import "./Home.css";

import { useNavigate } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

import Btn from "components/Btn";
import Princing from "components/Princing";
import ModalTerms from "components/ModalTerms";
import ModalPrivacy from "components/ModalPrivacy";

import { GetCurrentLanguageData, RegisterChangeLanguageObservers, UnregisterChangeLanguageObservers } from "interfaces/LocalizationInterface.js";
import { ShowModal } from "interfaces/ModalInterface.js";

import { phoneMask } from "services/Utils/formMasks.js";

import landingPage_configpage from "./assets/landingPage_configpage.png";
import group_2 from "./assets/group-2.svg";
import pexels_anna_nekrashevich_7552568_1 from "./assets/pexels-anna-nekrashevich-7552568-1.png";
import group from "./assets/group.png";
import usersthree from "./assets/usersthree.svg";
import logo from "./assets/logo.svg";
import social_instagram from "./assets/social_instagram.svg";
import social_facebook from "./assets/social_facebook.svg";
import social_youtube from "./assets/social_youtube.svg";
import item_01 from "./assets/item_01.png";
import item_02 from "./assets/item_02.png";
import item_03 from "./assets/item_03.png";

export default function Home() {

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [itemSelected, setItemSelected] = useState(1);
  const [textLocalization, setTextLocalization] = useState(null);

  const navigate = useNavigate();

  function HandleLanguageChange() {
    setTextLocalization(GetCurrentLanguageData("Home"));
  }

  useEffect(() => {
    HandleLanguageChange();
    RegisterChangeLanguageObservers(HandleLanguageChange);
    return () => {
      UnregisterChangeLanguageObservers(HandleLanguageChange);
    }
  }, []);

  return (
    <div className="Home">
      <div className="desktop">
        <div className="div-2">
          <div className="overlap">
            <div className="overlap-group">
              <div className="frame">
                <div className="frame-2">
                  <p className="p">{textLocalization && textLocalization["Section1"]["Title"]}</p>
                  <p className="text-wrapper-4">{textLocalization && textLocalization["Section1"]["Subtitle"]}</p>
                  <img className="group" alt="" src={group_2} />
                </div>
                <div className="menu">
                  <Btn className="design-component-instance-node" text={textLocalization && textLocalization["Section1"]["Button1"]} clickFunction={() => { navigate("/register") }} />
                  <div className="text-wrapper-5">{textLocalization && textLocalization["Section1"]["Button2"]}</div>
                </div>
              </div>
              <img className="criar-relatorio-2" alt="Criar relatorio" src={landingPage_configpage} />
            </div>
            <div className="container-part2">
              <img className="pexels-anna" alt="" src={pexels_anna_nekrashevich_7552568_1} />
              <div className="content-text">
                <p className="relat-rios-que">
                  <span className="span">{textLocalization && textLocalization["Section2"]["Title"]}</span>
                  <span className="text-wrapper-6">{textLocalization && textLocalization["Section2"]["TitleSpan"]}</span>
                </p>
                <p className="text-wrapper-7">
                  {textLocalization && textLocalization["Section2"]["Description"]}
                </p>
              </div>
            </div>
          </div>
          <div className="overlap-2">
            <div className="title-solutions">
              <div className="crie-conecte">
                {textLocalization && textLocalization["Section3"]["Title1"]}<br />
                {textLocalization && textLocalization["Section3"]["Title2"]}<br />
                {textLocalization && textLocalization["Section3"]["Title3"]}
              </div>
              <Btn className="design-component-instance-node" clickFunction={() => { navigate("/register") }} />
              <img className="group-2" alt="" src={group} />
            </div>
            <div className="overlap-3" id="Home_Section_1">
              <div className="solutions">
                {itemSelected === 1 ? (
                  <div className="item-solution">
                    <p className="item-number">{textLocalization && textLocalization["Section3"]["Step1"]["Number"]}</p>
                    <div className="text-wrapper-8">{textLocalization && textLocalization["Section3"]["Step1"]["Title"]}</div>
                    <p className="text-wrapper-9">
                      {textLocalization && textLocalization["Section3"]["Step1"]["Description"]}
                    </p>
                    <div className="item-spacer"></div>
                    <div className="item-line">
                      <div className="item-circle"></div>
                    </div>
                    <div className="item-buttoncontainer">
                      <div className="item-button-change-item item-button-change-selected" onClick={() => { setItemSelected(1) }}>1</div>
                      <div className="item-button-change-item" onClick={() => { setItemSelected(2) }}>2</div>
                      <div className="item-button-change-item" onClick={() => { setItemSelected(3) }}>3</div>
                    </div>
                    <img className="item-img" alt="" src={item_01} />
                  </div>
                ) : null}

                {itemSelected === 2 ? (
                  <div className="item-solution">
                    <p className="item-number">{textLocalization && textLocalization["Section3"]["Step2"]["Number"]}</p>                    
                    <div className="text-wrapper-8">{textLocalization && textLocalization["Section3"]["Step2"]["Title"]}</div>
                    <p className="text-wrapper-9">
                      {textLocalization && textLocalization["Section3"]["Step2"]["Description"]}
                    </p>
                    <div className="item-spacer"></div>
                    <div className="item-line">
                      <div className="item-circle"></div>
                    </div>
                    <div className="item-buttoncontainer">
                      <div className="item-button-change-item" onClick={() => { setItemSelected(1) }}>1</div>
                      <div className="item-button-change-item item-button-change-selected" onClick={() => { setItemSelected(2) }}>2</div>
                      <div className="item-button-change-item" onClick={() => { setItemSelected(3) }}>3</div>
                    </div>
                    <img className="item-img" alt="" src={item_02} />
                  </div>
                ) : null}

                {itemSelected === 3 ? (
                  <div className="item-solution">
                    <p className="item-number">{textLocalization && textLocalization["Section3"]["Step3"]["Number"]}</p>                    
                    <div className="text-wrapper-8">{textLocalization && textLocalization["Section3"]["Step3"]["Title"]}</div>
                    <p className="text-wrapper-9">
                      {textLocalization && textLocalization["Section3"]["Step3"]["Description"]}
                    </p>
                    <div className="item-spacer"></div>
                    <div className="item-line">
                      <div className="item-circle"></div>
                    </div>
                    <div className="item-buttoncontainer">
                      <div className="item-button-change-item" onClick={() => { setItemSelected(1) }}>1</div>
                      <div className="item-button-change-item" onClick={() => { setItemSelected(2) }}>2</div>
                      <div className="item-button-change-item item-button-change-selected" onClick={() => { setItemSelected(3) }}>3</div>
                    </div>
                    <img className="item-img" alt="" src={item_03} />
                  </div>
                ) : null}
              </div>
            </div>

          </div>
          <div className="title-pricing">
            <p className="text-wrapper-16">{textLocalization && textLocalization["Section4"]["Title"]}</p>
            <p className="text-wrapper-17">
              {textLocalization && textLocalization["Section4"]["Text1"]}
            </p>
          </div>
          <Princing
            cardPriceText={textLocalization && textLocalization["Section4"]["Plan3"]["ListTitle"]}
            cardPriceUsersThree={usersthree}
            texts={ textLocalization && textLocalization["Section4"]}
          />
          <div className="overlap-4" id="Home_Section_3" >
            <div className="contact">
              <p className="text-wrapper-14">
                {textLocalization && textLocalization["Section5"]["TextCard1"]}
              </p>
              <p className="text-wrapper-15">
                {textLocalization && textLocalization["Section5"]["TextCard2"]}
              </p>
              <img className="group-3" alt="Group" src={group} />
            </div>
            <div className="overlap-group-2">
              <div className="form-contact">
                <p className="text-wrapper-10">{textLocalization && textLocalization["Section5"]["Title"]}</p>
                <div className="form">
                  <input type="email" name="email" placeholder={textLocalization && textLocalization["Section5"]["InputEmail"]} className="input" maxLength={100} value={email} onChange={(e) => { setEmail(e.target.value) }} required />
                  <input type="text" name="name" placeholder={textLocalization && textLocalization["Section5"]["InputName"]} className="input" maxLength={100} value={name} onChange={(e) => { setName(e.target.value) }} required />
                  <input type="text" name="phone" placeholder={textLocalization && textLocalization["Section5"]["InputPhone"]} className="input" value={phone} onChange={(e) => { setPhone(phoneMask(e.target.value)) }} />
                  <select className="input" defaultValue="01">
                    <option value="01" hidden className="text-wrapper-11">{textLocalization && textLocalization["Section5"]["InputSubject"]}</option>
                    {textLocalization && textLocalization["Section5"]["InputSubjectOptions"].map((option, index) => (
                      <option key={index} value={option} className="text-wrapper-11">{option}</option>
                    ))}
                  </select>
                  <textarea placeholder={textLocalization && textLocalization["Section5"]["InputMessage"]} maxLength={500} className="div-wrapper" required />

                </div>
                <div className="input-2">
                  <div className="text-wrapper-13">{textLocalization && textLocalization["Section5"]["InputButton"]}</div>
                </div>
              </div>
            </div>

          </div>


          <footer className="footer">
            <div className="info-links">
              <img className="img-2" alt="Logo" src={logo} />
              <div className="menu-2">
                <div className="text-wrapper-18"><HashLink smooth to="/#Home_Section_1">{textLocalization && textLocalization["Footer"]["Link1"]}</HashLink></div>
                <div className="text-wrapper-18"><HashLink smooth to="/#Home_Section_2">{textLocalization && textLocalization["Footer"]["Link2"]}</HashLink></div>
                <div className="text-wrapper-18"><HashLink smooth to="/#Home_Section_3">{textLocalization && textLocalization["Footer"]["Link3"]}</HashLink></div>
                <div className="text-wrapper-18" onClick={()=>{ navigate("about") }} >{textLocalization && textLocalization["Footer"]["Link4"]}</div>
                <div className="text-wrapper-18" onClick={ ()=>{ShowModal("privacy", {}, ()=>{}) } }  >{textLocalization && textLocalization["Footer"]["Link5"]}</div>
                <div className="text-wrapper-18" onClick={ ()=>{ShowModal("terms", {}, ()=>{}) } } >{textLocalization && textLocalization["Footer"]["Link6"]}</div>
              </div>
              <div className="social-media-container">
                <img className="img-2" alt="Instagram" src={social_instagram} onClick={()=>{ window.open(textLocalization["Footer"]["InstagramLink"], "_blank") }} />
                <img className="img-2" alt="Facebook" src={social_facebook} onClick={()=>{ window.open(textLocalization["Footer"]["FacebookLink"], "_blank") }} />
                <img className="img-2" alt="Youtube" src={social_youtube} onClick={()=>{ window.open(textLocalization["Footer"]["YoutubeLink"], "_blank") }} />
              </div>
            </div>
            <div className="copyright">
              <p className="text-wrapper-19">{textLocalization && textLocalization["Footer"]["CopyRight"]}</p>
            </div>
          </footer>
        </div>
      </div>
      <ModalTerms />
      <ModalPrivacy />
    </div>
  );
}
