import { useEffect, useRef, useState } from "react";
import uuid from 'react-uuid';

import "./SummaryAddPicture.css";

import { CallPDFFunction } from "interfaces/PdfInterface.js";
import { GetCurrentLanguageData, RegisterChangeLanguageObservers, UnregisterChangeLanguageObservers } from "interfaces/LocalizationInterface.js";
import { SetLayoutData } from "interfaces/LayoutInterface.js";

import PlusAddPicture from "./assets/PlusAddPicture.svg";

export default function SummaryAddPicture(props) {

    const [text, setText] = useState("");

    let ImgList = [];
    let remainingProcess = 0;

    const UploadImagesForm = useRef();
    const UploadImagesInput = useRef();

    function HandleUploadClick(event) {
        event.preventDefault();
        UploadImagesInput.current.click();
    }

    function HandleLanguageChange(){
        const data = GetCurrentLanguageData("PageConfig");
        setText(data["Step2"]["AddImageButton"]);
    }

    function VerifyRemainingProcess() {
        remainingProcess--;
        if (remainingProcess === 0) {
            SetLayoutData("loadingScreen", false);
            CallPDFFunction("AddImage", { id: props.id, images: ImgList });
        }
    }

    useEffect(() => {
        UploadImagesInput.current.onchange = () => {
            SetLayoutData("loadingScreen", true);
            ImgList = [];
            const files = UploadImagesInput.current.files;
            remainingProcess = files.length;
            for (let i = 0; i < files.length; i++) {
                const uid = uuid();

                const reader = new FileReader();
                reader.onloadend = function () {

                    // Generate thumbnail
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext("2d");
                    const img = new Image();
                    img.src = reader.result;
                    img.onload = function () {
                        canvas.width = 50;
                        canvas.height = 50;
                        ctx.drawImage(img, 0, 0, 50, 50);
                        const thumbnail = canvas.toDataURL("image/jpeg");
                        ImgList[i] = {
                            id: uid,
                            imgSrc: reader.result,
                            imgSrcThumbnail: thumbnail,
                            type: "SummaryImages",
                            description: "",
                            addPage: true,
                        };
                        VerifyRemainingProcess();
                    };

                };
                reader.readAsDataURL(files[i]);
            }
        };
        RegisterChangeLanguageObservers(HandleLanguageChange);
        HandleLanguageChange();


        return () => {
            UnregisterChangeLanguageObservers(HandleLanguageChange);
        }
    }, []);

    return (
        <div
            className="add-picture"
        >
            <form ref={UploadImagesForm}>
                <button
                    onClick={(e) => {
                        HandleUploadClick(e);
                    }}
                >
                    <div className="text-wrapper">{text}</div>
                    <img className="plus" alt="" src={PlusAddPicture} />
                </button>
                <input
                    type="file"
                    name="my_file[]"
                    ref={UploadImagesInput}
                    multiple
                ></input>
            </form>
        </div>
    );
}
