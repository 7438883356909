import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import "./Register.css";

import ModalTerms from "components/ModalTerms";
import ModalPrivacy from "components/ModalPrivacy";

import Logo1 from "assets/Login_Logo1.png";
import Logo2 from "assets/Login_Logo2.png";

import { CreateUser, IsEmailVerified } from "services/Database/Firebase.js";
import { ShowModal } from "interfaces/ModalInterface.js";

export default function Register(){

    const [formName, setFormName] = useState("");
    const [formEmail, setFormEmail] = useState("");
    const [formPassword, setFormPassword] = useState("");
    const [passwordLength, setPasswordLength] = useState(0);
    const [passwordHasUpperCase, setPasswordHasUpperCase] = useState(false);
    const [passwordHasLowerCase, setPasswordHasLowerCase] = useState(false);
    const [passwordHasNumber, setPasswordHasNumber] = useState(false);
    const [passwordHasSpecialCharacter, setPasswordHasSpecialCharacter] = useState(false);    
    const [formConfirmPassword, setFormConfirmPassword] = useState("");
    const [formIP, setFormIP] = useState("");
    const [formLat, setFormLat] = useState("");
    const [formLon, setFormLon] = useState("");
    const [formCity, setFormCity] = useState("");
    const [formRegion, setFormRegion] = useState("");
    const [formCountry, setFormCountry] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();

    function HandlePasswordChange(input){
        setFormPassword(input);
        setPasswordLength(input.length);
        setPasswordHasUpperCase(input.toLowerCase() !== input);
        setPasswordHasLowerCase(input.toUpperCase() !== input);
        setPasswordHasNumber(/\d/.test(input));
        setPasswordHasSpecialCharacter(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(input));  
    }

    let verifyInterval = null;

    function VerificationSuccess(){
        console.log("E-mail verificado com sucesso");
        clearInterval(verifyInterval);
        navigate("/account");
    }

    function VerificationError(error){
        console.log("Erro ao verificar e-mail");
    }

    function RegisterSuccess(){
        console.log("Usuário criado com sucesso");

        setErrorMessage("Usuário criado com sucesso. Verifique seu e-mail para continuar");

        verifyInterval = setInterval(() => {
            IsEmailVerified(VerificationSuccess, VerificationError);
        }, 5000);

    }

    function RegisterError(error){
        console.log("Erro ao criar usuário");
        setErrorMessage(error);
    }
    
    function OnSubmitRegisterForm(event){
        event.preventDefault();

        if(formEmail.length < 5){
            setErrorMessage("E-mail inválido");
            return;
        }

        if(formPassword.length < 6){
            setErrorMessage("Senha inválida");
            return;
        }

        if(formPassword !== formConfirmPassword){
            setErrorMessage("Senhas não conferem");
            return;
        }

        if(formName.length < 3){
            setErrorMessage("Nome inválido");
            return;
        }
        
        CreateUser({
            name: formName,
            email: formEmail,
            password: formPassword,
            country: formCountry,
            region: formRegion,
            city: formCity,
            ip: formIP,
            lat: formLat,
            lon: formLon,
            accept: true
        }, RegisterSuccess, RegisterError);

    }

    useEffect(()=>{          
        
        fetch('http://ip-api.com/json')
        .then(function (response) {
            return response.json();
        })
        .then(function (json) {
            setFormCity(json.city);
            setFormRegion(json.regionName);
            setFormCountry(json.country);
            setFormIP(json.query);
            setFormLat(json.lat);
            setFormLon(json.lon);

        })
        .catch(function (error) {
            fetch('https://api.ipregistry.co/?key=tryout')
            .then(function (response) {
                return response.json();
            })
            .then(function (payload) {
                setFormCity(payload.location.city);
                setFormRegion(payload.location.region.name);
                setFormCountry(payload.location.country.name);
                setFormIP(payload.ip);
                setFormLat(payload.location.latitude);
                setFormLon(payload.location.longitude);
            });
        });
        
        return () => {
            clearInterval(verifyInterval);
        }
        
    },[]);

    return (

        <div className="registre-se">
            <div className="detail">
                <p className="text">
                    Faça relatórios simples e sem complicações!
                </p>
                <img className="img" alt="Group" src={Logo1} />
            </div>
            <div className="container">
                <div className="div-2">
                    <img className="group" alt="Group" src={Logo2} />
                    <div className="title">
                        <div className="text-wrapper">Comece agora mesmo!</div>
                        <div className="div">
                            Experimente totalmente grátis!
                        </div>
                    </div>
                    <form className="form" onSubmit={OnSubmitRegisterForm}>
                        <input className="input" type="text" name="name" placeholder="Nome completo" value={formName} onChange={(e) => setFormName(e.target.value)}  required></input>
                        <input className="input" type="email" name="email" placeholder="E-mail" value={formEmail} onChange={(e) => setFormEmail(e.target.value)}  required></input>
                        <input className="input" type="password" name="password" placeholder="Senha" value={formPassword} onChange={(e) => HandlePasswordChange(e.target.value)}  required></input>
                        <div className="info-password">
                            <div className="col">
                                <div className="item-pass">
                                    <div className={`ellipse ${passwordHasUpperCase ? "ellipse-valid" : ""}`} />
                                    <div className="text-wrapper-3">
                                        1 Letra maiúscula
                                    </div>
                                </div>
                                <div className="item-pass">
                                    <div className={`ellipse ${passwordHasLowerCase ? "ellipse-valid" : ""}`} />
                                    <div className="text-wrapper-3">
                                        1 Letra minúscula
                                    </div>
                                </div>
                                <div className="item-pass">
                                    <div className={`ellipse ${passwordHasNumber ? "ellipse-valid" : ""}`} />
                                    <div className="text-wrapper-3">
                                        1 Numero
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="item-pass">
                                    <div className={`ellipse ${passwordHasSpecialCharacter ? "ellipse-valid" : ""}`} />
                                    <div className="text-wrapper-3">
                                        1 caracter especial
                                    </div>
                                </div>
                                <div className="item-pass">
                                    <div className={`ellipse ${passwordLength >= 8 ? "ellipse-valid" : ""}`} />
                                    <div className="text-wrapper-3">
                                        8 caracteres no mínimo
                                    </div>
                                </div>
                            </div>
                        </div>
                        <input className="input" type="password" name="confirmPassword" placeholder="Confirme a senha" value={formConfirmPassword} onChange={(e) => setFormConfirmPassword(e.target.value)} required >
                            
                        </input>
                        <p>{errorMessage}</p>
                        <button type="submit"  className="div-wrapper">
                            <div className="text-wrapper-4">Registrar agora!</div>
                        </button>
                    </form>
                    
                    <div className="cadastre-se">
                        <p className="text-wrapper-5">
                            Ao criar uma conta você concorda com os
                        </p>
                        <div className="termos-politicas">
                            <div className="text-wrapper-6" onClick={ ()=>{ShowModal("terms", {}, ()=>{}) } }>
                                Termos de Serviço
                            </div>
                            <div className="text-wrapper-5">e</div>
                            <div className="text-wrapper-6" onClick={ ()=>{ShowModal("privacy", {}, ()=>{}) } } >
                                Politicas de Uso.
                            </div>
                        </div>
                    </div>
                    <div className="cadastre-se-2">
                        <div className="text-wrapper-5">
                            Já possui uma conta?
                        </div>
                        <div className="text-wrapper-6" onClick={()=>{ navigate("/login")}}>Entre agora</div>
                    </div>
                </div>
            </div>
            <ModalTerms />
            <ModalPrivacy />
        </div>
    );
}