import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import "./Login.css";

import { SignInUser, IsEmailVerified } from "services/Database/Firebase.js";

import Logo1 from "assets/Login_Logo1.png";
import Logo2 from "assets/Login_Logo2.png";
import ArrowRight from "./assets/arrowright.svg";

export default function Login(){

    const [formEmail, setFormEmail] = useState("");
    const [formPassword, setFormPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();

    function LoginSuccess(){
        IsEmailVerified(VerificationSuccess, VerificationError);        
    }

    function LoginError(error){
        setErrorMessage(error);
    }

    function VerificationSuccess(){
        clearInterval(verifyInterval);
        navigate("/account");
    }

    function VerificationError(error){
        console.log(error);
    }

    let verifyInterval = null;

    function EmailNotVerified(){
        setErrorMessage("Por favor, verifique seu e-mail para continuar");
        verifyInterval = setInterval(() => {
            IsEmailVerified(VerificationSuccess, VerificationError);
        }, 5000);
    }

    function OnSubmitLoginForm(event){
        event.preventDefault();

        if(formEmail.length < 5){
            setErrorMessage("E-mail inválido");
            return;
        }

        if(formPassword.length < 6){
            setErrorMessage("Senha inválida");
            return;
        }

        SignInUser({email: formEmail, password: formPassword}, LoginSuccess, LoginError, EmailNotVerified);

    }

    useEffect(() => {        

        return () => {
            if(verifyInterval != null){
                clearInterval(verifyInterval);
            }
        }
    }, []);

    return (
        

        <div className="login">
             <div className="detail">
                <p className="text-wrapper-6">
                    Faça relatórios simples e sem complicações!
                </p>
                <img className="img" alt="Group" src={Logo1} />
            </div>
            <div className="div">
                <div className="div-2">
                    <img className="group" alt="Group" src={Logo2} />
                    <div className="title">
                        <div className="text-wrapper">Seja bem-vindo</div>
                        <p className="p">
                            Faça o login abaixo para acessar sua conta
                        </p>
                    </div>
                    <form className="form" onSubmit={OnSubmitLoginForm}>
                        <input className="input" type="email" name="email" placeholder="E-mail" value={formEmail} onChange={(e) => setFormEmail(e.target.value)} required></input>
                        <input className="input" type="password" name="password" placeholder="Senha" value={formPassword} onChange={(e) => setFormPassword(e.target.value)} required></input>
                        <p className="error-message">{errorMessage}</p>
                        <div className="action-login">
                            <div className="frame">                               
                            </div>
                            <div className="text-wrapper-4">
                                Esqueci minha senha
                            </div>
                        </div>
                        <button type="submit"  className="div-wrapper">
                            <div className="text-wrapper-5">Acessar</div>
                        </button>
                    </form>                    
                    <div className="cadastre-se">
                        <div className="text-wrapper-3">
                            Ainda não possui conta?
                        </div>
                        <div className="btn-registrar" onClick={()=>{ navigate("/register")}}>
                            <div className="text-wrapper-4">Cadastre-se</div>
                            <img
                                className="arrow-right"
                                alt="Arrow right"
                                src={ArrowRight}
                            />
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
}