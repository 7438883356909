import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";

import './index.css';

import MainMenu from "pages/MainMenu";
import MenuSpacer from "components/MenuSpacer";
import LoadingScreen from "components/LoadingScreen";

import Axios from "axios";

import { SetInitialLanguagesData } from "interfaces/LocalizationInterface.js";
import { InitializeFirebase } from "services/Database/Firebase.js";

import RoutesComponent from './RoutesComponent.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

Axios.get(process.env.REACT_APP_JSON_LOCALIZATION).then(({data}) => { 
    SetInitialLanguagesData(data, "pt-br");

    InitializeFirebase();

    root.render(
        <Router>
            <Fragment>
                <MainMenu />
                <MenuSpacer />
                <div>      
                    <RoutesComponent>
                    </RoutesComponent>
                </div>
                </Fragment>      
                <LoadingScreen />      
        </Router>
    );
    
});
