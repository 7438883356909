import "./HistoryElement.css";

import PDFIcon from "./assets/filetext.svg";

import { ShowModal, HideModal } from "interfaces/ModalInterface.js";
import { DeletePDFData } from "services/Database/Firebase.js";

import trashIcon from "./assets/TrashImageGrey.svg";

export default function HistoryElement(props){

    function SuccessDeletePDF(data){
        console.log(data);
        HideModal("confirm");
        props.generateList(data);
    }

    function ErrorDeletePDF(error){
        console.log(error);
    }

    function DeletePDF(){
        console.log(props.pdfID);
        DeletePDFData(props.pdfID, SuccessDeletePDF, ErrorDeletePDF);
    }  

    return (
        <div className={`card-report`} >
            <div className="detail" onClick={()=>{ShowModal("pdfDetails", {"title": props.name, "url": props.link, "cancelText": "Voltar"}, "", ()=>{}) }} />
            <div className="title-desc" onClick={()=>{ShowModal("pdfDetails", {"title": props.name, "url": props.link, "cancelText": "Voltar"}, "", ()=>{}) }}>
                <p className="text-wrapper">{props.name}</p>
                <div className="div">{props.date}</div>
            </div>
            <img className="file-text" alt="File text" src={PDFIcon} onClick={()=>{ShowModal("pdfDetails", {"title": props.name, "url": props.link, "cancelText": "Voltar"}, "", ()=>{}) }} />
            <img src={trashIcon} alt="" className="trash-icon"  onClick={()=>{ShowModal("confirm", {"title":"Deseja deletar o relatório?", "confirmText":"Confirmar", "cancelText":"Cancelar"}, DeletePDF) }} />
        </div>        
    );
}