
import { useState } from "react";
import "./Switch.css";

import { UpdatePDFConfig } from "interfaces/PdfInterface.js";

export default function Switch(props){ 

  const [enable, setEnable] = useState(false);

  function ToggleSwitch(){
    UpdatePDFConfig(props.configKey, !enable);
    setEnable(!enable);
  }

  return (
    <div className={`switch ${ enable ? "switch-enabled" : ""}`}  onClick={ToggleSwitch} >
      <div className={`thumb-disable ${ enable ? "thumb-enabled" : ""}`}>
            <div className={`track-disable ${ enable ? "track-enabled" : ""}`} />
            <div className={`div-disable ${ enable ? "div-enabled" : ""}`} />
      </div>
    </div>
  );
};

