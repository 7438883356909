import { useState, useEffect } from "react";

import "./PageItemScroll.css";

import { GetPhotoData, GetPDFConfigData, UpdatePDFConfig, RegisterPDFConfigObserver, UnregisterPDFConfigObserver } from "interfaces/PdfInterface.js";

export default function PageItemScroll(props){

  const [selected, setSelected] = useState(false);
  const [pageOrientation, setPageOrientation] = useState("");

  function HandleClick(){
    UpdatePDFConfig("selectedPage", props.index)
  }

  function UpdateSelectedPage(){
    setSelected(JSON.stringify(GetPDFConfigData("selectedPage")) === JSON.stringify(props.index));
  }

  function HandleSheetOrientation(){
    const orientation  = GetPDFConfigData("sheetOrientation");
    if(orientation === "portrait"){
        setPageOrientation("");
    }else if(orientation === "landscape"){
        setPageOrientation("-landscape");
    }
  }

  function GetLength(){
    let length = 0;
    if(GetPhotoData(props.data[0]) === undefined){
      return 0;
    }
    props.data.forEach((item, i) =>{
      if(GetPhotoData(item).type === "SummaryImages"){
        length++;
      }
    })
    return length;
  }

  useEffect(() => {
    setSelected(JSON.stringify(GetPDFConfigData("selectedPage")) === JSON.stringify(props.index));
    RegisterPDFConfigObserver("sheetOrientation", HandleSheetOrientation);
    RegisterPDFConfigObserver("selectedPage", UpdateSelectedPage);
    HandleSheetOrientation();

    return () => {
      UnregisterPDFConfigObserver("sheetOrientation", HandleSheetOrientation);
      UnregisterPDFConfigObserver("selectedPage", UpdateSelectedPage);
    }
  }, []);

    return(
      <div className={`page-item ${pageOrientation === "-landscape" ? "page-item-landscape" : ""}`}>
        <div className={`page-item-2${pageOrientation} ${selected ? "selectedPage" : ""}`} onClick={(e) => { HandleClick(); }}>
          <div className="header-2">
            <img className="logo-2" alt="Logo" src={props.logo} />
            <div className="text-wrapper-11">Área externa</div>
          </div>
          <div className="frame-4">
            <p className="text-wrapper-12">Obra - Centro de Minas Gerais</p>
            <div className="text-wrapper-12">{props.index + 1}</div>
          </div>
          {GetLength() > 0 ?
            <div className={pageOrientation === "-landscape" ? "list-photos-landscape" : "list-photos"}>
              {props.data.map((item, i) => {
                return GetPhotoData(item).type === "SummaryImages" ? <div key={i} className={pageOrientation === "-landscape" ? `rectangle-landscape-${GetLength()}` : `rectangle-${GetLength()}`} /> : null
              })}
            </div>
            : null}
        </div>
        <div className={`text-wrapper-13${pageOrientation}`}>{props.index + 1}</div>
      </div>
    );
}