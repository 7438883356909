import { useState, useEffect } from "react";

import "./ModalConfirm.css";

import { GetCurrentLanguageData, RegisterChangeLanguageObservers, UnregisterChangeLanguageObservers } from "interfaces/LocalizationInterface.js";
import { RegisterPDFFunction, UnregisterPDFFunction, CallPDFFunction } from "interfaces/PdfInterface.js";
import { HideModal, RegisterChangeModalsStateObservers, UnregisterChangeModalsStateObservers, CallFunction } from "interfaces/ModalInterface.js";

export default function ModalConfirm(){

    const [state, setState] = useState(false);
    const [content, setContent] = useState({
            "title": "",
            "confirmText": "",
            "cancelText": "",        
    });

    function OnChangeModalsState(newState, newContent){        
        setState(newState);
        setContent(newContent);       
    }

    function clickConfirm(){
        CallFunction("confirm", "onConfirm");
    }

    useEffect(() => {
        RegisterChangeModalsStateObservers("confirm", OnChangeModalsState);
        return () => {
            UnregisterChangeModalsStateObservers("confirm", OnChangeModalsState);
        }
    }, []);

    return (
        <div className="modal-confirm-wrapper" style={ state ? { display: "flex" } : { display: "none" }}>
            <div className="modal-confirm">
                <div className="modal-confirm-title">{content.title}</div>
                {/* <div className="modal-confirm-text">{props.text}</div> */}
                <div className="modal-confirm-buttons">
                    <div className="modal-confirm-button" onClick={clickConfirm}>{content.confirmText}</div>
                    <div className="modal-confirm-button" onClick={()=>{HideModal("confirm")}}>{content.cancelText}</div>
                </div>
            </div>
        </div>
    );
}