import { useState, useEffect } from "react";

import "./EditImageModal.css";

import FilerobotImageEditor, {
    TABS,
    TOOLS,
} from 'react-filerobot-image-editor';

import { RegisterPDFFunction, UnregisterPDFFunction, GetObjectIndexByID, GetPhotoData, DeletePhotoElement, SetPhotoData, GetNextPhotoData, GetPrevPhotoData, SetPhotoDescription, SetPhotoSrc } from "interfaces/PdfInterface.js";
import { GetCurrentLanguageData, RegisterChangeLanguageObservers, UnregisterChangeLanguageObservers } from "interfaces/LocalizationInterface.js";

import NotePencilWhiteIcon from "./assets/NotePencilWhite.svg";
import TrashIcon from "./assets/TrashImage.svg";
import RightArrowIcon from "./assets/CaretDown.svg";
import Placeholder from "./assets/PlaceholderImage.png";

export default function EditImageModal(){

    const [enableModal, setEnableModal] = useState(false);
    const [currentID, setCurrentID] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [imageData, setImageData] = useState({});
    const [imageWidth, setImageWidth] = useState("0px");
    const [imageHeight, setImageHeight] = useState("0px");
    const [description, setDescription] = useState("");
    const [imgBlob, setImgBlob] = useState(null);
    const [src, setSrc] = useState(null);
    const [nextImageData, setNextImageData] = useState(0);
    const [prevImageData, setPrevImageData] = useState(0);
    const [textEdit, setTextEdit] = useState("");
    const [textClose, setTextClose] = useState("");
    const [isImgEditorShown, setIsImgEditorShown] = useState(false);

    function OpenModal(data){
        document.body.style.overflowY = 'hidden';
        setCurrentID(data);
        const indexElement = GetObjectIndexByID(data);
        setCurrentIndex(indexElement);
        const responseData = GetPhotoData(indexElement);
        setImageData(responseData);
        setImgBlob(responseData.imgSrc);

        setDescription(responseData.description);        
        setEnableModal(true);
        GetNextPrevImageIndex(indexElement);
    }

    function CloseModal(e){
        e.stopPropagation();        
        SetPhotoData(currentIndex, {
            id: imageData.id,
            imgSrc: imageData.imgSrc,
            imgSrcThumbnail: imageData.imgSrcThumbnail,
            type: imageData.type,
            description: description,
            addPage: imageData.addPage           
        });
        document.body.style.overflowY = 'auto';
        setEnableModal(false);
    }

    function HandleDescriptionChange(e){
        SetPhotoDescription(currentIndex, e.target.value);
        setDescription(e.target.value);
    }

    function GetNextPrevImageIndex(index){
        let nextIndex = GetNextPhotoData(index);
        let prevIndex = GetPrevPhotoData(index);
        if(nextIndex != -1){
            setNextImageData(nextIndex.id);
        }else{
            setNextImageData(-1);
        }
        if(prevIndex != -1){
            setPrevImageData(prevIndex.id);
        }else{
            setPrevImageData(-1);
        }
    }
        
    function HandleLanguageChange(){
        const data = GetCurrentLanguageData("PageConfig");
        setTextEdit(data["Step2"]["EditImage"]);
        setTextClose(data["Step2"]["CloseImage"]);
    }

    const openImgEditor = () => {
        setIsImgEditorShown(true);
        console.log(localStorage.getItem("FIE_pinnedColors"));
    };

    const closeImgEditor = () => {
        setIsImgEditorShown(false);
    };

    useEffect(()=>{
        RegisterPDFFunction("OpenModalEditImage", OpenModal);    
        RegisterChangeLanguageObservers(HandleLanguageChange);
        HandleLanguageChange();  
        
        return () => {
            UnregisterPDFFunction("OpenModalEditImage", OpenModal);
            UnregisterChangeLanguageObservers(HandleLanguageChange);
        }

    },[]);

    useEffect(()=>{        
        if(imgBlob){
            if (imgBlob instanceof Blob) {
                const reader  = new FileReader();
                reader.onloadend = function () {
                    setSrc(reader.result); 
                    let image = new Image();  
                    image.onload = function() {
                        if(image.width > image.height){
                            setImageWidth("600px");
                            setImageHeight("auto");
                        }else{
                            setImageWidth("auto");
                            setImageHeight("600px");
                        }              
                    }; 
                    image.src = reader.result;
                }
                reader.readAsDataURL(imgBlob); 
            } else {
                setSrc(imgBlob); 
                let image = new Image();  
                image.onload = function() {
                    if(image.width > image.height){
                        setImageWidth("600px");
                        setImageHeight("auto");
                    }else{
                        setImageWidth("auto");
                        setImageHeight("600px");
                    }              
                }; 
                image.src = imgBlob;
            } 
                
        }      
    },[imgBlob]);
    

    return (
        <>
            <div
                className="backdropEditImageModal"
                style={enableModal ? { display: "flex" } : { display: "none" }}
                draggable={false}
            ></div>
            <div
                className="editImageModal"
                style={enableModal ? { display: "flex" } : { display: "none" }}
            >
                <div className="content">
                    <div className="sideButtonsContainer">
                        {prevImageData !== -1 ? 
                        <div className="sideButtons" onClick={()=>{ 
                            OpenModal(prevImageData);
                         }}>
                            <img src={RightArrowIcon} alt="" className="leftSide" />
                        </div>
                         : null
                        }

                    </div>
                    <div className="imageContainer">
                        <div className="imageElement">
                            <img src={src || Placeholder} alt="" style={{width: imageWidth, height: imageHeight}} />
                        </div>
                        <div className="description">
                            <textarea type="text" placeholder="Descrição" maxLength="700" value={description} onChange={(e)=>{HandleDescriptionChange(e)}}></textarea>
                        </div>
                        <div className="buttonContainer">
                            <div className="edit" onClick={()=>{openImgEditor()}}>
                                <p>{textEdit}</p>
                                <img src={NotePencilWhiteIcon} alt="" />
                            </div>
                            <div className="delete" onClick={(e)=>{ CloseModal(e); DeletePhotoElement(currentID)}}>
                                <img src={TrashIcon} alt="" />
                            </div>
                            <div className="cancel" onClick={(e)=>{CloseModal(e)}}>
                                <p>{textClose}</p>
                            </div>
                        </div>
                    </div>
                    <div className="sideButtonsContainer">
                        {nextImageData != -1 ?
                        <div className="sideButtons" onClick={()=>{ 
                            OpenModal(nextImageData);
                         }}>
                            <img src={RightArrowIcon} alt="" />
                        </div>
                        : null
                        }
                    </div>
                </div>
            </div>

            {isImgEditorShown && (
        <FilerobotImageEditor
          source={src || Placeholder}
          onSave={(editedImageObject, designState) => {
                console.log('saved', editedImageObject, designState);
                setSrc(editedImageObject.imageBase64);
                SetPhotoSrc(currentIndex, editedImageObject.imageBase64);
                closeImgEditor();
            }
          }
          onBeforeSave={()=>false}
          onClose={closeImgEditor}
          translations={{
            name: 'Nome',
            save: 'Finalizar',
            saveAs: 'Salvar como',
            back: 'Voltar',
            loading: 'Carregando...',
            resetOperations: 'Reiniciar/Deletar tudo',
            changesLoseConfirmation: 'Todas as alterações serão perdidas.',
            changesLoseConfirmationHint: 'Certeza que deseja continuar?',
            cancel: 'Cancelar',
            continue: 'Continuar',
            undoTitle: 'Voltar última operação',
            redoTitle: 'Refazer última operação',
            showImageTitle: 'Mostrar imagem original',
            zoomInTitle: 'Zoom in',
            zoomOutTitle: 'Zoom out',
            toggleZoomMenuTitle: 'Toggle zoom menu',
            adjustTab: 'Ajustar',
            finetuneTab: 'Ajuste fino',
            filtersTab: 'Filters',
            watermarkTab: 'Watermark',
            annotateTab: 'Desenhar',
            resize: 'Redimensionar',
            resizeTab: 'Redimensionar',
            invalidImageError: 'Invalid image provided.',
            uploadImageError: 'Error while uploading the image.',
            areNotImages: 'are not images',
            isNotImage: 'is not image',
            toBeUploaded: 'to be uploaded',
            cropTool: 'Cortar',
            original: 'Original',
            custom: 'Customizado',
            square: 'Quadrado',
            landscape: 'Paisagem',
            portrait: 'Retrato',
            ellipse: 'Elípse',
            classicTv: 'TV',
            cinemascope: 'Cinema',
            arrowTool: 'Seta',
            blurTool: 'Borrado',
            brightnessTool: 'Brilho',
            contrastTool: 'Contraste',
            ellipseTool: 'Elípse',
            unFlipX: 'Desvirar X',
            flipX: 'Virar X',
            unFlipY: 'Desvirar Y',
            flipY: 'Virar Y',
            hsvTool: 'HSV',
            hue: 'Hue',
            saturation: 'Saturação',
            value: 'Valor',
            imageTool: 'Imagem',
            importing: 'Importando...',
            addImage: '+ Adicionar imagem',
            uploadImage: 'Upload image',
            fromGallery: 'Da galeria',
            lineTool: 'Linha',
            penTool: 'Caneta',
            polygonTool: 'Polígono',
            sides: 'Sides',
            rectangleTool: 'Retângulo',
            cornerRadius: 'Raio da borda',
            resizeWidthTitle: 'Largura em pixels',
            resizeHeightTitle: 'Altura em pixels',
            toggleRatioLockTitle: 'Toggle ratio lock',
            reset: 'Reiniciar',
            resetSize: 'Reiniciar para o tamanho original',
            rotateTool: 'Rotacionar',
            textTool: 'Texto',
            textSpacings: 'Espaçamento',
            textAlignment: 'Alinhamento',
            fontFamily: 'Fonte',
            size: 'Tamanho',
            letterSpacing: 'Espaçamento entre letras',
            lineHeight: 'Altura da linha',
            warmthTool: 'Temperatura',
            addWatermark: '+ Add watermark',
            addTextWatermark: '+ Add text watermark',
            addWatermarkTitle: 'Choose the watermark type',
            uploadWatermark: 'Upload watermark',
            addWatermarkAsText: 'Adicionar como texto',
            padding: 'Padding',
            shadow: 'Sombra',
            horizontal: 'Horizontal',
            vertical: 'Vertical',
            blur: 'Borrado',
            opacity: 'Opacidade',
            position: 'Posição',
            stroke: 'Stroke',
            saveAsModalLabel: 'Salvar imagem como',
            extension: 'Extensão',
            nameIsRequired: 'O nome é necessário.',
            quality: 'Qualidade',
            imageDimensionsHoverTitle: 'Saved image size (width x height)',
            cropSizeLowerThanResizedWarning:
              'Note, the selected crop area is lower than the applied resize which might cause quality decrease',
            actualSize: 'Actual size (100%)',
            fitSize: 'Fit size',
            addImageTitle: 'Seleciona a imagem...',
            mutualizedFailedToLoadImg: 'Falha ao carregar imagem.',
          }}
          theme={{
            palette: {
                'bg-primary-active': '#ECF3FF',
              },
            typography: {
                fontFamily: 'Space Grotesk'
            }
            
          }}
          // annotationsCommon={{
          //   fill: '#ff000000',
          // }}
          Text={{ text: 'Lorem ipsum...' }}
          Rotate={{ angle: 90, componentType: 'buttons' }}
        //   Crop={{
        //     presetsItems: [
        //       {
        //         titleKey: '4:3',
        //         descriptionKey: '4:3',
        //         ratio: 4 / 3,
        //         // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
        //       },
        //       {
        //         titleKey: '21:9',
        //         descriptionKey: '21:9',
        //         ratio: 21 / 9,
        //         // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
        //       },
        //     ]            
        //   }}
          tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.FINETUNE]} // or {['Adjust', 'Annotate', 'Watermark']}
          defaultTabId={TABS.ADJUST} // or 'Annotate'
          defaultToolId={TOOLS.TEXT} // or 'Text'
        />
      )}             
        </>
    );
}