import { useState, useEffect } from "react";

import "./ModalPrivacy.css";

import { GetCurrentLanguageData } from "interfaces/LocalizationInterface.js";
import { HideModal, RegisterChangeModalsStateObservers, UnregisterChangeModalsStateObservers } from "interfaces/ModalInterface.js";

import CloseIcon from "assets/Close.svg";

export default function ModalConfirm(){

    const [state, setState] = useState(false);
    const [content, setContent] = useState({
            "Title": "",
            "Paragraphs": [],      
    });

    function OnChangeModalsState(newState, newContent){   
        setState(newState);
        setContent(GetCurrentLanguageData("Modals")["PrivacyPolicy"]);       
    }   
    
    function ClickBackdrop(event){
        if(event.target.className === "modal-privacy-wrapper" || event.target.className === "modal-privacy-closebutton"){
            HideModal("privacy");
        }
    }

    useEffect(() => {
        RegisterChangeModalsStateObservers("privacy", OnChangeModalsState);
        return () => {
            UnregisterChangeModalsStateObservers("privacy", OnChangeModalsState);
        }
    }, []);

    return (
        <div className="modal-privacy-wrapper" style={ state ? { display: "flex" } : { display: "none" }} onClick={(e)=>{ClickBackdrop(e)}}>
            <div className="modal-privacy">
                <div className="modal-privacy-title">{content.Title}</div>
                {content.Paragraphs.map((item, index) => {
                    return (
                        <div key={index} className="modal-privacy-text">{item}</div>
                    );
                })}
                {/* <div className="modal-privacy-buttons">                    
                    <div className="modal-privacy-button" onClick={()=>{HideModal("terms")}}>{content.cancelText}</div>
                </div> */}
                <div className="modal-privacy-closebutton">
                    <img src={CloseIcon} alt="" onClick={()=>{HideModal("privacy")}} />
                </div>
            </div>
        </div>
    );
}