import { useState, useEffect } from "react";

import "./ModalPDFDetails.css";

import { HideModal, RegisterChangeModalsStateObservers, UnregisterChangeModalsStateObservers, CallFunction } from "interfaces/ModalInterface.js";

import CopyIcon from "./assets/Copy.svg";
import DownloadIcon from "./assets/DownloadSimple.svg";

export default function ModalPDFDetails(){

    const [state, setState] = useState(false);
    const [content, setContent] = useState({
            "title": "",
            "url": "",
            "cancelText": "",        
    });
    const [btClicked, setBtClicked] = useState(false);

    function OnChangeModalsState(newState, newContent){        
        setState(newState);
        setContent(newContent);       
    }    

    function ClickScaleButton(){
        setBtClicked(true);
        setTimeout(() => {
            setBtClicked(false);
        }, 200);
    }

    useEffect(() => {
        RegisterChangeModalsStateObservers("pdfDetails", OnChangeModalsState);
        return () => {
            UnregisterChangeModalsStateObservers("pdfDetails", OnChangeModalsState);
        }
    }, []);

    return (
        <div
            className="modal-pdfdetails-wrapper"
            style={state ? { display: "flex" } : { display: "none" }}
        >
            <div className="modal-pdfdetails">
                <div className="modal-pdfdetails-title">{content.title}</div>
                {/* <div className="modal-pdfdetails-text">{props.text}</div> */}

                <div className="orientacao-folha-3 copyContainer">
                    <div
                        className="title-2"
                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                        <p
                            className="copyText"
                            onClick={() => {
                                ClickScaleButton();
                                navigator.clipboard.writeText(content.url);
                            }}
                        >
                            {content.url}
                        </p>
                    </div>
                    <img
                        className={btClicked ? "img click" : "img"}
                        alt=""
                        src={CopyIcon}
                        onClick={() => {
                            ClickScaleButton();
                            navigator.clipboard.writeText(content.url);
                        }}
                    />
                </div>
                <div className="orientacao-folha-3">
                    <a href={content.url} target="_blank" rel="noreferrer" download>
                    <div className="downloadButton">
                        <div className="title-3">
                            <p>Download</p>
                        </div>
                        <img className="img" alt="" src={DownloadIcon} />
                    </div>
                    </a>
                </div>
                <div className="modal-pdfdetails-buttons">
                    <div
                        className="modal-pdfdetails-button"
                        onClick={() => {
                            HideModal("pdfDetails");
                        }}
                    >
                        {content.cancelText}
                    </div>
                </div>
            </div>
        </div>
    );
}