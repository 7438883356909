import Btn from "components/Btn";
import "./style.css";

export default function CardPrice({
  className = "",
  hasUsersThree = true,
  hasDiv = true,
  btnText = "Experimente grátis",
  text = "Text description",
  check,
  img = "/img/check-23.svg",
  text1 = "Starded",
  text2 = "Grátis",
  rectangleClassName,
  divClassName,
  divClassNameOverride,
  divClassName1,
  divClassName2,
  divClassName3,
  divClassName4,
  hasPrice = true,
  usersThree = "/img/usersthree-1.svg",
}){
  return (
    <div className={`card-price ${className}`}>
      <div className="div">
        <div className="starded">{text1}</div>
      </div>
      {hasPrice && (
        <div className="price">
          <div className="gr-tis">{text2}</div>
        </div>
      )}

      {hasUsersThree && <img className="users-three" alt="Users three" src={usersThree} />}

      <div className="div">{hasDiv && <div className="text-wrapper">Economize R$50</div>}</div>
      <Btn className="btn-instance" text={btnText} />
      <div className={`rectangle ${rectangleClassName}`} />
      <div className="text-description">{text}</div>
      <div className="item-description">
        <img className="check" alt="Check" src={check} />
        <div className={`text-description-2 ${divClassName}`}>{text}</div>
      </div>
      <div className="item-description">
        <img className="check" alt="Check" src={img} />
        <div className={`text-description-2 ${divClassNameOverride}`}>{text}</div>
      </div>
      <div className="item-description">
        <img className="check" alt="Check" src={check} />
        <div className={`text-description-2 ${divClassName1}`}>{text}</div>
      </div>
      <div className="item-description">
        <img className="check" alt="Check" src={check} />
        <div className={`text-description-2 ${divClassName2}`}>{text}</div>
      </div>
      <div className="item-description">
        <img className="check" alt="Check" src={check} />
        <div className={`text-description-2 ${divClassName3}`}>{text}</div>
      </div>
      <div className="item-description">
        <img className="check" alt="Check" src={check} />
        <div className={`text-description-2 ${divClassName4}`}>{text}</div>
      </div>
    </div>
  );
};
