import { useState, useEffect, useRef, memo } from "react";

import "./PagePreviewItem.css";

import { GetPDFConfigData, CallPDFFunction, GetImageIndexByID } from "interfaces/PdfInterface.js";

function PagePreviewItem(props) {

    const [imageSrc, setImageSrc] = useState(null);
    const [cardConfig, setCardConfig] = useState("portrait6");

    const CardRef = useRef(null);

    function ChangeCardConfig(){
        // setCardConfig(GetPDFConfigData("sheetOrientation") + GetPDFConfigData("picturesPerPage")); 
        setCardConfig(GetPDFConfigData("sheetOrientation") + props.imagesCount); 
    }

    function SetLineHeight(){
        if(props.renderPDF){
            switch (cardConfig) {
                case "portrait6":
                    return "22px";
                case "portrait5":
                    return "22px";
                case "portrait4":
                    return "27px";
                case "portrait3":
                    return "22px";
                case "portrait2":
                    return "26px";
                case "portrait1":
                    return "26px";
                case "landscape6":
                    return "21px";
                case "landscape5":
                    return "21px";
                case "landscape4":
                    return "20.4px";
                case "landscape3":
                    return "27px";
                case "landscape2":
                    return "27px";
                case "landscape1":
                    return "22px";
                default:
                    return "26px";
            }
        }else{
            switch (cardConfig) {
                case "portrait6":
                    return "11px";
                case "portrait5":
                    return "11px";
                case "portrait4":
                    return "13.5px";
                case "portrait3":
                    return "11px";
                case "portrait2":
                    return "13px";
                case "portrait1":
                    return "13px";
                case "landscape6":
                    return "10.5px";
                case "landscape5":
                    return "10.5px";
                case "landscape4":
                    return "10.2px";
                case "landscape3":
                    return "13.5px";
                case "landscape2":
                    return "13.5px";
                case "landscape1":
                    return "11px";
                default:
                    return "13px";
            }
        }
    }

    function SetFontSize(){
        if(props.renderPDF){
            return "24px";
        }else{
            return "12px";
        }
    }

    useEffect(()=>{        
        if (props.img instanceof Blob) {
            const reader  = new FileReader();
            reader.onloadend = function () {
                setImageSrc(reader.result); 
                let image = new Image();  
                image.onload = function() {
                             
                }; 
                image.src = reader.result;
            }
            reader.readAsDataURL(props.img);
        } else {
            setImageSrc(props.img); 
            let image = new Image();  
            image.onload = function() {
                             
            }; 
            image.src = props.img;
        } 
        ChangeCardConfig();
    },[]);

    return (
        <div className={`page-preview-item ${cardConfig}`} ref={CardRef} onClick={()=>{
            if(GetPDFConfigData("pdfFinished") === false){
                CallPDFFunction("OpenModalEditImage", props.id);
            }
            }}
            style={{border: props.renderPDF ? "2px solid var(--collor-grey-400)" : "1px solid var(--collor-grey-400)"}}
            >
            <div className="img">
                <div className="overlap-group" style={ props.imgCover ? { backgroundImage: `url(${imageSrc})`, backgroundSize: 'cover' } : { backgroundImage: `url(${imageSrc})`, backgroundSize: 'contain' }}>
                    {/* <img className="rectangle" alt="" src={imageSrc} />                    */}
                </div>
            </div>
            <div className="description">
                <p className="div" 
                    style={ {fontSize: SetFontSize(), lineHeight: SetLineHeight()} }
                >
                    {props.description}
                </p>
            </div>
            <div className="number-photo">
                <div className="text-wrapper-2">{GetImageIndexByID(props.id) + 1}</div>
            </div>
        </div>
    );
}

export default memo(PagePreviewItem);