import { useState, memo } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import "./SummaryTopic.css";

import SummaryPage from 'components/SummaryPage';
import SummaryAddPicture from 'components/SummaryAddPicture';

import DotsSixVertical from "./assets/DotsSixVertical.svg";
import TrashIcon from "./assets/TrashImage.svg";

import { DeletePhotoElement } from "interfaces/PdfInterface.js";

function SummaryTopic(props){

  const [visibleAdds, setVisibleAdds] = useState(true);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
} = useSortable({id: props.id});

const style = {
  transform: CSS.Translate.toString(transform),
  transition
}

  return (
    <div  ref={setNodeRef} style={style} {...attributes} {...listeners} >
      <div className="title-cat" >
        <img className="dots-six-vertical" alt="" src={DotsSixVertical} />
        <div className="text-wrapper">{props.title}</div>
        <img className="trash-icon" alt="" src={TrashIcon} onClick={()=>{DeletePhotoElement(props.id)}} />
      </div>
      <div style={visibleAdds ? {  } : { display: 'none' } } >
        <SummaryPage />
        { props.next != "SummaryImages" ? <SummaryAddPicture id={props.id} /> : null }
      </div>
    </div>
  );
};

export default memo(SummaryTopic);