const ModalsState = {
    "confirm": false,
    "pdfDetails": false,
    "terms": false,
    "privacy": false
}

const ModalContent = {
    "confirm": {
        "title": "",
        "confirmText": "",
        "cancelText": ""
    },
    pdfDetails: {
        "title": "",
        "url": "",
        "cancelText": ""
    },
    terms: {
        "title": "",
        "termsText": ""
    },
    privacy: {
        "title": "",
        "privacyText": ""
    }
}

const ModalsStateObservers = {
    confirm: [],
    pdfDetails: [],
    terms: [],
    privacy: []
};

const ModalsCallbacks = {
    confirm: {
        "onConfirm": null
    },
    pdfDetails: {
        "onConfirm": null
    },
    terms: {
        "onConfirm": null
    },
    privacy: {
        "onConfirm": null
    }
}

export function ShowModal(key, props, func){
    ModalsState[key] = true;
    ModalContent[key] = props;
    ModalsCallbacks[key]["onConfirm"] = func;
    CallChangeModalsStateObservers(key);
}

export function HideModal(key){
    ModalsState[key] = false;
    CallChangeModalsStateObservers(key);
}

export function CallFunction(key, functionName){
    ModalsCallbacks[key][functionName]();
}

export function CallChangeModalsStateObservers(key){
    ModalsStateObservers[key].forEach((callback) => {
        callback(ModalsState[key], ModalContent[key]);
    });
}

export function RegisterChangeModalsStateObservers(key, callback){
    ModalsStateObservers[key].push(callback);
}

export function UnregisterChangeModalsStateObservers(key, callback){
    ModalsStateObservers[key] = ModalsStateObservers[key].filter((item) => {
        return item !== callback;
    });
}

