const AccountTypes = {
    0: "free",
    1: "silver",
    2: "gold"
}

const UserData = {
    username: "",
    accountType: AccountTypes[0]
}

const UserDataObservers = [];

export function SetUserData(data){
    if(data.username){
        UserData.username = data.username;
    }
    if(data.accountType){
        UserData.accountType = data.accountType;
    }
    NotifyUserDataObservers();
}

export function GetAccountType(){
    return UserData.accountType;
}

export function GetUserData(){
    return UserData;
}

export function RegisterUserDataObserver(callback){
    UserDataObservers.push(callback);
}

export function UnregisterUserDataObserver(callback){
    const index = UserDataObservers.indexOf(callback);
    if(index > -1){
        UserDataObservers.splice(index, 1);
    }
}

export function NotifyUserDataObservers(){
    UserDataObservers.forEach((callback) => {
        callback();
    });
}