import { useEffect, useState } from 'react';

import './LoadingScreen.css';

import TubeSpinner from './assets/tube-spinner.svg';

import { RegisterLayoutDataObserver, UnregisterLayoutDataObserver, GetLayoutData } from "interfaces/LayoutInterface.js";

export default function LoadingScreen(){

    const [showLoadingScreen, setShowLoadingScreen] = useState(false);

    function ActivateLoadingScreen(){
        setShowLoadingScreen(true);
        document.body.style.overflow = "hidden";
    }

    function DeactivateLoadingScreen(){
        setShowLoadingScreen(false);
        document.body.style.overflow = "auto";
    }

    function HandleLoadingScreen(){
        if(GetLayoutData("loadingScreen")){
            ActivateLoadingScreen();
        }else{
            DeactivateLoadingScreen();
        }
    }

    useEffect(() => {
        RegisterLayoutDataObserver("loadingScreen", HandleLoadingScreen);
        return () => {
            UnregisterLayoutDataObserver("loadingScreen", HandleLoadingScreen);
        }
    }
    , []);
    
    return (
        <div className="LoadingScreen">
            { showLoadingScreen ?  
            <div className="logoContainer" >
               <img src={TubeSpinner} alt="PhotoClick Logo" className="loadingImg" />
            </div>    
            : null }        
        </div>
    );
}