import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./Account.css";

import HistoryElement from "components/HistoryElement";
import ModalPDFDetails from "components/ModalPDFDetails";

import { IsEmailVerified, GetPDFData } from "services/Database/Firebase.js";

import PlusIcon from "./assets/Plus.svg";
import LogoIcon from "./assets/icon-logo.png";
import ModalConfirm from "components/ModalConfirm";

export default function Account(){

    const [displayForm, setDisplayForm] = useState(false);
    const [displayButton, setDisplayButton] = useState(true);
    const [opacityForm, setOpacityForm] = useState(0);
    const [opacityButton, setOpacityButton] = useState(1);
    const [pdfsData, setPdfsData] = useState([]);

    const navigate = useNavigate();

    function ChangeFormState(forward){
        if(forward){
            setDisplayForm(true);
            setOpacityButton(0);
            setTimeout(() => {
                setOpacityForm(1);
                setTimeout(() => {
                    setDisplayButton(false);
                }, 200);
            }, 200);
        }else{
            setDisplayButton(true);
            setOpacityForm(0);
            setTimeout(() => {
                setOpacityButton(1);
                setTimeout(() => {
                    setDisplayForm(false);
                }, 200);
            }, 200);
        }
    }

    function OnSubmitNewName(event){
        event.preventDefault();
    }

    function GetPDFsSuccess(data){
        const pdfs = [];
        Object.keys(data).forEach((key)=>{
            if(data[key].deleted === true) return;
            const Day = new Date(data[key].timestamp);            
            const month = (Day.getMonth() + 1).toString().padStart(2, '0');
            const DayString = `${Day.getDate().toString().padStart(2, '0')}/${month}`;

            pdfs.push({
                id: data[key].id,
                title: data[key].title,
                pdfName: data[key].url.split("/").pop(),
                url: data[key].url,
                timestamp: DayString,
                deleted: data[key].deleted               
            });
        });
        // console.log(pdfs);
        setPdfsData(pdfs);
    }


    function UserIsLogged(){
        // console.log("Usuário logado");
        GetPDFData(GetPDFsSuccess, ()=>{navigate("/")});
    }

    useEffect(() => {
        IsEmailVerified(UserIsLogged, ()=>{
            console.log("Usuário não logado");
            navigate("/");
        });
    }, []);

    return (

        <div className="account">
            <div className="div-2">
                <div className="section">
                <div className="content">
                    <div className="title-btn">
                    <div className="text-wrapper-2">Criar relatório</div>
                    <div className="rectangle" />
                    <div className="btn" onClick={()=>{ navigate("/pageconfig")}} >
                        <img className="plus" alt="Plus" src={PlusIcon} />
                        <div className="text-wrapper-3">Novo</div>
                    </div>
                    </div>
                    <div className="banner-cta">
                    <img className="icon-logo" alt="Icon logo" src={LogoIcon} />
                    <div className="title">
                        <p className="p">Faça relatórios simples e sem complicações!</p>
                        <div className="text-wrapper-4">Uma assinatura, muitas possibilidades</div>
                    </div>
                    <div className="div-wrapper">
                        <div className="text-wrapper-5">Assinar agora</div>
                    </div>
                    </div>
                    <div className="last-reports">
                    <div className="title-viewall">
                        <div className="text-wrapper-6">Últimos relatórios</div>
                        {/* <div className="text-wrapper-7">ver todos</div> */}
                    </div>
                    <div className="list-reports">
                        { pdfsData.reverse().map((pdf, index) => {   
                            return (
                                pdf.deleted ? null :  <HistoryElement key={index} name={pdf.title} date={pdf.timestamp} link={pdf.url} pdfName={pdf.pdfName} pdfID={pdf.id} generateList={GetPDFsSuccess} />
                            );
                        })}
                    </div>
                    </div>
                </div>
                </div>                
            </div>
            <ModalPDFDetails />
            <ModalConfirm />
        </div>
    );
}

