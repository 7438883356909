import { useState, useEffect } from 'react';
import { DndContext, rectIntersection, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import "./PicturesSort.css";

import SummaryTopic from 'components/SummaryTopic';
import SummaryImages from 'components/SummaryImages';

import { RegisterPDFFunction, UnregisterPDFFunction, AddPhotoElement, UpdatePDFData } from "interfaces/PdfInterface.js";
import { GetCurrentLanguageData, RegisterChangeLanguageObservers, UnregisterChangeLanguageObservers } from "interfaces/LocalizationInterface.js";

export default function PicturesSort(){

    const [draggableElements, setDraggableElements] = useState([]);
    const [pageText, setPageText] = useState("");

    const sensors = useSensors(
        useSensor(PointerSensor, {
          activationConstraint: {
            distance: 8,
          },
        })
    );

    function handleOnDragEndDnd(result){
        if(result.active && result.over){        
            if(result.active.id !== result.over.id){
                const OldIndex = draggableElements.findIndex(item => item.id === result.active.id);
                const NewIndex = draggableElements.findIndex(item => item.id === result.over.id);  
                UpdatePDFData(arrayMove(draggableElements, OldIndex, NewIndex));
            }
        }
    }   

    function AddImage(id){  
        AddPhotoElement(id);
    }

    function AddTopic(){        
        
    }

    function UpdateDraggableElements(data){        
        setDraggableElements([...data]);
    }

    function UpdateLanguages(){
        setPageText(GetCurrentLanguageData("PageConfig")["Step2"]["Page"]);
    }

    function UpdateCard(){
        setPageText(GetCurrentLanguageData("PageConfig")["Step2"]["Page"]);
        const SummaryPageRef = document.querySelectorAll(".SummaryPage");
        SummaryPageRef.forEach((element, index) => {
            element.querySelector(".div").innerHTML = index + 1;
            element.querySelector(".text-wrapper").innerHTML = `${GetCurrentLanguageData("PageConfig")["Step2"]["Page"]} ${(index + 1)}`;
        });
        const SummaryImageRef = document.querySelectorAll(".photo-info");
        SummaryImageRef.forEach((element, index) => {
            element.querySelector(".image-index").innerHTML = index + 1;           
        });     
    }

    useEffect(() => {
        RegisterPDFFunction("AddImage", AddImage);
        RegisterPDFFunction("AddTopic", AddTopic);
        RegisterPDFFunction("UpdateData", UpdateDraggableElements);
        RegisterPDFFunction("UpdateImageListIndexes", UpdateCard);
        RegisterChangeLanguageObservers(UpdateLanguages);
        setPageText(GetCurrentLanguageData("PageConfig")["Step2"]["Page"]);

        UpdateCard();
        
        return () => {
            UnregisterPDFFunction("AddImage", AddImage);
            UnregisterPDFFunction("AddTopic", AddTopic);
            UnregisterPDFFunction("UpdateData", UpdateDraggableElements);
            UnregisterPDFFunction("UpdateImageListIndexes", UpdateCard);
            UnregisterChangeLanguageObservers(UpdateLanguages);
        }
    }, []);

    useEffect(() => {
        setPageText(GetCurrentLanguageData("PageConfig")["Step2"]["Page"]);
        UpdateCard();
    }, [draggableElements]);

    return(
        <div style={{width: "100%"}}>
            <DndContext
                    collisionDetection={rectIntersection}
                    onDragEnd={handleOnDragEndDnd}
                    sensors={sensors}
                >
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '12px', touchAction: 'none'}}>
                        <SortableContext
                            items={draggableElements.map(item => item.id)}
                            strategy={verticalListSortingStrategy}                            
                        >
                            {draggableElements.map((element, i) => {
                                switch(element.type){
                                    case "SummaryTopic":
                                        return <SummaryTopic key={element.id} id={element.id} title={element.title} next={ draggableElements[i+1] ? draggableElements[i+1].type : 'none' } previous={ draggableElements[i-1] ? draggableElements[i-1].type : 'none' } />;                                    
                                    case "SummaryImages":
                                        return <SummaryImages key={element.id} id={element.id} addPage={element.addPage} next={ draggableElements[i+1] ? draggableElements[i+1].type : 'none' } previous={ draggableElements[i-1] ? draggableElements[i-1].type : 'none' } imgSrc={element.imgSrc} description={element.description} imgSrcThumbnail={element.imgSrcThumbnail} />; 
                                    default:
                                        return null;                                   
                                }
                            })}   
                        </SortableContext>
                    </div>
                </DndContext>

                    
        </div>
    );
}