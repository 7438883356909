import { useState, useEffect, memo } from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import "./SummaryImages.css";

import SummaryPage from 'components/SummaryPage';
import SummaryAddPicture from 'components/SummaryAddPicture';

import { DeletePhotoElement, CallPDFFunction, RegisterPDFFunction, UnregisterPDFFunction, GetObjectIndexByID, GetImageIndexByID, GetPhotoData } from "interfaces/PdfInterface.js";

import DotsSixVertical from "./assets/DotsSixVertical.svg";
import NotePencil from "./assets/NotePencilImages.svg";
import Trash from "./assets/TrashImage.svg";
import TrashGrey from "./assets/TrashImageGrey.svg";

function SummaryImages(props) {

    const [showOptions, setShowOptions] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: props.id});
  
    const style = {
      transform: CSS.Translate.toString(transform),
      transition
    }

    function UpdateCard(){
        const index = GetImageIndexByID(props.id);
        if(index !== undefined){
            return;
        }
        console.log(GetPhotoData(index));
        setImageSrc(props.imgSrcThumbnail);
    }
   
    useEffect(()=>{
        setImageSrc(props.imgSrcThumbnail);        
        
        RegisterPDFFunction("UpdateData", UpdateCard); 
        
        return () => {
            UnregisterPDFFunction("UpdateData", UpdateCard);
        }
        
    },[]);
    
    return (
        <>
        <div className="backdropSummaryImage" style={showOptions ? {display: 'flex'} : {}} onClick={(e)=>{e.stopPropagation(); setShowOptions(false); }} draggable={false}></div>
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <div>
                { props.previous === "none" ? <SummaryPage /> : null }
                { props.addPage ? <SummaryPage /> : null }
            </div>
            <div className={`photo-info`}>
                <div className="image-index">00</div>
                <div className="rectangle">
                    <img src={imageSrc} alt="" style={{width: "50px", height: "50px", cursor: "pointer"}} onClick={()=>{CallPDFFunction("OpenModalEditImage", props.id)}}></img>
                </div>
                <div className="frame">
                    <p className="p">
                        {props.description.length > 160 ? `${props.description.slice(0, 160)}...` : props.description}
                    </p>
                </div>
                <img className="trashIcon" alt="" src={TrashGrey} onClick={()=>{DeletePhotoElement(props.id)}} />
                <img className="dots-six-vertical" alt="" src={DotsSixVertical} />                
                <div className={`modal-actions`} style={ showOptions ? { display: "flex" } : {} }>
                    <div className="item-link-perfil" onClick={()=>{CallPDFFunction("OpenModalEditImage", props.id); setShowOptions(false);}}>
                        <img className="img" alt="" src={NotePencil} />
                        <div className="sair">Editar</div>
                    </div>
                    <div className="div" onClick={()=>{DeletePhotoElement(props.id)}}>
                        <img className="img" alt="" src={Trash} />
                        <div className="text-wrapper">Excluir</div>
                    </div>
                </div>
            </div>
            <div>
                { props.next !== "SummaryImages" ? <SummaryAddPicture id={props.id} /> : null }                
            </div>            
        </div>
        </>
    );
}

export default memo(SummaryImages);