import { useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";

import Home from "pages/Home";
import Login from "pages/Login";
import Register from "pages/Register";
import Account from "pages/Account";
import SheetConfig from "pages/SheetConfig";
import ImageUpload from "pages/ImageUpload";
import About from 'pages/About';
import Tutorial from 'pages/Tutorial';

import { SetLayoutData } from "interfaces/LayoutInterface.js";

export default function RoutesComponent(){

    let location = useLocation();

    useEffect(() => {
        // console.log(location.pathname);
        switch(location.pathname){
            case "/":
                SetLayoutData("showMenu", true);
                break;
            case "/login":
                SetLayoutData("showMenu", false);
                break;
            case "/register":
                SetLayoutData("showMenu", false);
                break;
            case "/account":
                SetLayoutData("showMenu", true);
                break;
            case "/pageconfig":
                SetLayoutData("showMenu", false);
                break;
            case "/upload":
                SetLayoutData("showMenu", true);
                break;
            case "/about":
                SetLayoutData("showMenu", true);
                break;
            case "/tutorial":
                SetLayoutData("showMenu", true);
                break;
            default:
                SetLayoutData("showMenu", true);
                break;
        }

    }, [location]);

    return(            
                    <Routes>
                        <Route path="/login" exact element={<Login />} />
                        <Route path="/register" exact element={<Register />} />
                        <Route path="/account" exact element={<Account />} />
                        <Route path="/pageconfig" exact element={<SheetConfig />} />
                        <Route path="/upload" exact element={<ImageUpload />} />
                        <Route path="/about" exact element={<About />} />
                        <Route path="/tutorial" exact element={<Tutorial />} />
                        <Route path="/" element={<Home />} />
                        <Route path="*" element={<Home />} />
                    </Routes>
    );
}