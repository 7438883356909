import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import uuid from 'react-uuid';

import "./SheetConfig.css";

import ArrowRightWhite from "./assets/ArrowRightWhite.svg";
import ArrowBendDownRight from "./assets/ArrowBendDownRight.svg";
import Plus from "./assets/Plus.svg";
import PlusAddNew from "./assets/PlusAddNew.svg";
import Check from "./assets/Check.svg";
import NotePencil from "./assets/NotePencil.svg";
import PagePortraitIcon from "./assets/PagePortraitIcon.svg";
import PageLandscapeIcon from "./assets/PageLandscapeIcon.svg";
import PagePortraitIconSelected from "./assets/PagePortraitIconSelected.svg";
import PageLandscapeIconSelected from "./assets/PageLandscapeIconSelected.svg";
import Logo from "./assets/logo.png";
import ArrowLineUp from "./assets/ArrowLineUp.svg";
// import PreviewImage from "./assets/PreviewImage.png";
import CopyIcon from "./assets/Copy.svg";
import DownloadIcon from "./assets/DownloadSimple.svg";
import CloseIcon from "assets/Close.svg";

import PicturesSort from 'components/PicturesSort';
import InputToggle from "components/InputToggle";
import NumPhotoPage from "components/NumPhotoPage";
import Switch from "components/Switch";
import PageItemScroll from "components/PageItemScroll";
import PagePreview from 'components/PagePreview';
import PagePDF from "components/PagePDF";
import EditImageModal from 'components/EditImageModal';
import ModalConfirm from 'components/ModalConfirm';

import { RegisterPDFConfigObserver, UnregisterPDFConfigObserver, UpdatePDFConfig, CallPDFFunction, RegisterPDFFunction, UnregisterPDFFunction, GetPDFConfigData, GetPhotoData, AddNewSection, GetLastIDPhotoData, ResetPDFConfig, DeleteAllPhotoElement } from "interfaces/PdfInterface.js";
import { GetCurrentLanguageData, RegisterChangeLanguageObservers, UnregisterChangeLanguageObservers } from "interfaces/LocalizationInterface.js";
import { IsEmailVerified } from "services/Database/Firebase.js";
import { ShowModal, HideModal } from "interfaces/ModalInterface.js";
import { SetLayoutData } from "interfaces/LayoutInterface.js";

export default function SheetConfig(){

    const [reportName, setReportName] = useState("");
    const [sheetOrientation, setSheetOrientation] = useState("portrait");     
    const [miniPages, setMiniPages] = useState([]);
    const [selectedPageTitle, setSelectedPageTitle] = useState("");
    const [selectedPageNumber, setSelectedPageNumber] = useState(1);
    const [newTopicName, setNewTopicName] = useState("");
    const [logoName, setLogoName] = useState("");
    const [imgCount, setImgCount] = useState("0");
    const [pageOrientation, setPageOrientation] = useState("");
    const [textLocalization, setTextLocalization] = useState({
        "Step1": {
            "Title": "",
            "Description": "",
            "HintText": "",
            "CancelButton": "",
            "NextButton": "",
        },
        "Step2": {
            "Title": "",
            "Description": "",
            "HintText": "",
            "CancelButton": "",
            "SkipButton": "",
            "NextButton": "",
            "AddCategoryButton": "",
            "AddImageButton": "",
            "ImagesPerPage": "",
        },
        "Step3": {
            "Title": "",
            "ReportNameInput": "",
            "ReportNameInputHint": "",
            "PageOrientation": "",
            "ImagesPerPage": "",
            "ImageQuantity": "",
            "ImageQuantityDescription": "",
            "AddPicturesButton": "",
            "AddPicturesDescription": "",
            "Summary": "",
            "LogoTitle": "",
            "LogoHint": "",
            "LogoButton": "",
            "BaseboardTitle": "",
            "BaseboardHint": "",
            "FinishButton": "",
            "CancelButton": "",
        }
    });
    const [reportState, setReportState] = useState(0);
    const [reportFinished, setReportFinished] = useState(false);
    const [reportLink, setReportLink] = useState("");
    const [finishClicked, setFinishClicked] = useState(false);
    const [skipText, setSkipText] = useState(false);

    let ImgList = [];
    let remainingProcess = 0;

    const UploadLogoInput = useRef();
    const UploadNewImagesInput = useRef();
    const ClickIconElement = useRef();

    const navigate = useNavigate();

    const LocalizationSection = "PageConfig";

    function HandleSheetOrientation(value){

        setSheetOrientation(value);

        
        if(value === "portrait"){
            setPageOrientation("");
        }else if(value === "landscape"){
            setPageOrientation("-landscape");
        }
    }

    function SubmitReportName(event){
        event.preventDefault();
        setReportState(1);
    }

    function HandleReportNameChange(name){
        setReportName(name);
    }

    function handleTopicNameKeyDown(event){
        if (event.key === 'Enter') {            
            AddNewTopicName();
        }
    }

    function HandleLogoInputClick(event){ 
        event.preventDefault();
        UploadLogoInput.current.click();
    }

    function AddNewTopicName(){
        if(newTopicName.length > 0){
            AddNewSection(newTopicName);
            setNewTopicName("");
        }
    }

    function HandleClickAddNewImages(event){
        event.preventDefault();
        UploadNewImagesInput.current.click();
    }

    function HandleCancelReport(){
        // console.log("Cancel report");
        navigate("/account");
    }

    function HandlePDFChange(data){        
        if(data[0] === undefined){
            setSkipText(true);
            return;
        }
        const imagePerPage = GetPDFConfigData("picturesPerPage");
        let imagePerPageCount = 0;
        let PageCount = 0;
        // let lastType = "";
        let PagesSetup = [];
        let imageCount = 0;
        let imageIdByIndex = {};
        if(data[0].type === "SummaryTopic"){
            PagesSetup.push([0]);
            // lastType = "SummaryTopic";
       }else if(data[0].type === "SummaryImages"){
            PagesSetup.push([0]);
            // lastType = "SummaryImages";
            imagePerPageCount++;
            imageCount++;
            imageIdByIndex[data[0].id] = imageCount
       }
        for(let i = 1; i < data.length; i++){
           if(data[i].type === "SummaryTopic"){
                PageCount++;
                PagesSetup.push([i]);
                // lastType = "SummaryTopic";
                imagePerPageCount = 0;
           }else if(data[i].type === "SummaryImages"){
                imagePerPageCount++;
                imageCount++;
                imageIdByIndex[data[i].id] = imageCount
                if(imagePerPageCount > imagePerPage){
                    imagePerPageCount = 1;
                    PageCount++;
                    PagesSetup.push([i]);
                }else{
                    PagesSetup[parseInt(PageCount)].push(i);
                }
                // lastType = "SummaryImages";                
           }       
        }
        setSkipText(false);
        setMiniPages([...PagesSetup]);
        setImgCount(imageCount);
        let currentPageIndex = GetPDFConfigData("selectedPage");
        if(currentPageIndex >= PagesSetup.length){
            currentPageIndex = 0;
            UpdatePDFConfig("selectedPage", currentPageIndex);
        }
        setSelectedPageNumber(currentPageIndex + 1);
        let pageTitle = "";
        const ImageData = [];
        PagesSetup[currentPageIndex].forEach((element, index) => {
            const PDFElement = GetPhotoData(element);
            if(PDFElement.type === "SummaryImages"){
                ImageData.push({
                    id: PDFElement.id,
                    img: PDFElement.imgSrc,
                    title: "",
                    description: PDFElement.description
                });
            }else if(PDFElement.type === "SummaryTopic"){
                pageTitle = PDFElement.title;
            }
        });
        if(currentPageIndex !== 0){
            if(pageTitle === ""){
                for(let i = currentPageIndex - 1; i >= 0; i--){
                    PagesSetup[i].forEach((element, index) => {
                        const PDFElement = GetPhotoData(element);
                        if(PDFElement.type === "SummaryTopic"){
                            pageTitle = PDFElement.title;
                        }
                    });
                    if(pageTitle !== ""){
                        break;
                    }
                }
            }   
        }
        if(pageTitle !== ""){
            setSelectedPageTitle(pageTitle);
        }else{
            setSelectedPageTitle("");
        }
        CallPDFFunction("ChangePreviewPage", ImageData);
    }

    function UpdateLocalization(){
        const TextData = GetCurrentLanguageData(LocalizationSection);
        setTextLocalization(TextData);
    }

    function HandleReportFinished(data){
        setReportFinished(true);
        setReportLink(data.link);
    }

    function VerifyRemainingProcess() {
        remainingProcess--;
        if (remainingProcess === 0) {
            SetLayoutData("loadingScreen", false);
            let lastID = GetLastIDPhotoData();            
            CallPDFFunction("AddImage", {id: lastID, images: ImgList});
        }
    }

    function PushImgListData(data, index){
        ImgList[index] = data;
    }

    function ReceiveNewItens(){
        SetLayoutData("loadingScreen", true);
        ImgList = [];
        remainingProcess = UploadNewImagesInput.current.files.length;
        for (let i = 0; i < UploadNewImagesInput.current.files.length; i++) {
            const uid = uuid();

            const reader = new FileReader();
            reader.onloadend = function () {

                // Generate thumbnail
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext("2d");
                const img = new Image();
                img.src = reader.result;
                img.onload = function () {
                    canvas.width = 50;
                    canvas.height = 50;
                    ctx.drawImage(img, 0, 0, 50, 50);
                    const thumbnail = canvas.toDataURL("image/jpeg");
                    PushImgListData({
                        id: uid,
                        imgSrc: reader.result,
                        imgSrcThumbnail: thumbnail,
                        type: "SummaryImages",
                        description: "",
                        addPage: true,
                    }, i);
                    VerifyRemainingProcess();
                };

            };
            reader.readAsDataURL(UploadNewImagesInput.current.files[i]);

        }
    }

    function ClickCopyLink(){
        ClickIconElement.current.style.transform = "scale(1.2)";
        setTimeout(() => {
            ClickIconElement.current.style.transform = "scale(1)";
        }, 200);

    }

    function RemoveLogo(){
        UpdatePDFConfig("logo", Logo);
        setLogoName("");
    }

    useEffect(()=>{
        IsEmailVerified(()=>{
        }, ()=>{
            navigate("/");
        });
        RegisterPDFConfigObserver("name", HandleReportNameChange);
        RegisterPDFConfigObserver("sheetOrientation", HandleSheetOrientation);
        RegisterPDFFunction("UpdateData", HandlePDFChange);
        RegisterPDFFunction("ReportFinished", HandleReportFinished);
        UpdatePDFConfig("picturesPerPage", "6");

        UploadLogoInput.current.onchange = () => {
            console.log("Logo changed");
            if(UploadLogoInput.current.files[0] !== undefined){
                const reader  = new FileReader();
                reader.onloadend = function () {  
                    UpdatePDFConfig("logo", reader.result);
                    setLogoName(UploadLogoInput.current.files[0].name);
                    UploadLogoInput.current.value = null;
                }
                reader.readAsDataURL(UploadLogoInput.current.files[0]);                   
            }
        };

        UploadNewImagesInput.current.onchange = () => {
            ReceiveNewItens();            
        };

        UpdateLocalization();
        RegisterChangeLanguageObservers(UpdateLocalization);
        

        return () => {
            UnregisterPDFConfigObserver("name", HandleReportNameChange);
            UnregisterPDFConfigObserver("sheetOrientation", HandleSheetOrientation);
            UnregisterPDFFunction("UpdateData", HandlePDFChange);
            UnregisterPDFFunction("ReportFinished", HandleReportFinished);
            UnregisterChangeLanguageObservers(UpdateLocalization);
        }
    },[]);

    useEffect(()=>{
        CallPDFFunction("UpdateImageListIndexes", null);
    },[reportState]);

    return (
        <div className="SheetConfig">
            <div style={ reportState === 0 ? {  width: "100%", height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' } : { display: 'none'}} >
            <form
                className="formName"
                onSubmit={(e) => {
                    SubmitReportName(e);
                }}
            >
                <div className="title">
                    <p className="text-wrapper">
                        {textLocalization["Step1"]["Title"]}
                    </p>
                    <p className="div">
                        {textLocalization["Step1"]["Description"]}
                    </p>
                </div>
                <input
                    className="input"
                    type="text"
                    id="id"
                    name="pdfname"
                    placeholder={textLocalization["Step1"]["HintText"]}
                    maxLength={30}
                    value={reportName}
                    onChange={(e) => UpdatePDFConfig("name", e.target.value)}
                    required
                ></input>
                <div className="input-counter">
                    {/* <div className="text-wrapper-2">Digite aqui</div> */}
                    <div className="text-wrapper-3">{reportName.length}/30</div>
                </div>
                <div className="frame">
                    <div className="div-wrapper">
                        <div className="text-wrapper-4" onClick={ ()=>{ ShowModal("confirm", {"title":"Deseja cancelar o relatório?", "confirmText":"Confirmar", "cancelText":"Cancelar"}, HandleCancelReport) } } >{textLocalization["Step1"]["CancelButton"]}</div>
                    </div>
                    <button type="submit" className="input-2">
                        <div className="text-wrapper-5">{textLocalization["Step1"]["NextButton"]}</div>
                        <img
                            className="arrow-right"
                            alt="Arrow right"
                            src={ArrowRightWhite}
                        />
                    </button>
                </div>
            </form>
            </div>

            <div style={  reportState === 1 ?  { width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }: { display: 'none'} }>
                <div style={{ height: '1vh'  }}></div>
                <div className="formName">
                    <div className="title">
                        <p className="text-wrapper">
                            {textLocalization["Step2"]["Title"]}
                        </p>
                        <p className="div">
                            {textLocalization["Step2"]["Description"]}
                        </p>
                    </div>

                    <PicturesSort />
                    <div className='RemoveAllPictures' style={ skipText ? { display: 'none' } : {  }}>
                        <p className='removeAllPictires' onClick={()=>{ShowModal("confirm", {"title":"Deseja remover todas as fotos?", "confirmText":"Confirmar", "cancelText":"Cancelar"}, ()=>{DeleteAllPhotoElement(); HideModal("confirm");});}}>Remover todas as fotos</p>
                    </div>
                    <input
                        className="input"
                        type="text"
                        placeholder={textLocalization["Step2"]["HintText"]}
                        value={newTopicName}
                        onChange={(event) => { setNewTopicName(event.target.value); }}
                        onKeyDown={(event) => { handleTopicNameKeyDown(event); }}
                    ></input>
                    <div className="input-counter">
                        <img
                            className="arrow-right"
                            alt=""
                            src={ArrowBendDownRight}
                            onClick={() => { AddNewTopicName(); }}
                        />
                        <div className="text-wrapper-3">{`${newTopicName.length}/30`}</div>
                    </div>

                    <div className='skipContainer' style={ newTopicName !== "" || !skipText ? { display: 'none' } : {  }}>
                        <p className='skipText'>Clique em avançar para pular esta etapa</p>
                        <button className="input-2" onClick={()=>{setReportState(2)}}>
                            <div className="text-wrapper-5" >{textLocalization["Step2"]["NextButton"]}</div>
                            <img
                                className="arrow-right"
                                alt="Arrow right"
                                src={ArrowRightWhite}
                            />
                        </button>
                    </div>

                    

                    <div className="addnew" style={ newTopicName === "" ? {height: "36px" } : {}}>
                        <div
                            className="addnew-text-wrapper"
                            onClick={() => { AddNewTopicName(); }}
                            style={ newTopicName === "" ? {display: "none" } : {}}
                        >
                            {textLocalization["Step2"]["AddCategoryButton"]}
                        </div>
                        <img
                            className="addnew-plus"
                            alt="Plus"
                            src={PlusAddNew}
                            onClick={() => { AddNewTopicName(); }}
                            style={ newTopicName === "" ? {display: "none" } : {}}
                        />
                    </div>

                    {/* <div className="frame">
                        <div className="div-wrapper">
                            <div className="text-wrapper-4" onClick={()=>{setReportState(2)}} >
                            {textLocalization["Step2"]["SkipButton"]}
                            </div>
                        </div>
                        <button className="input-2" onClick={()=>{setReportState(2)}} >
                            <div className="text-wrapper-5">{textLocalization["Step2"]["NextButton"]}</div>
                            <img
                                className="arrow-right"
                                alt=""
                                src={ArrowRightWhite}
                            />
                        </button>
                    </div> */}
                </div>
                <div className="formName bottom-next">
                    <div className="frame " style={ newTopicName !== "" || !skipText ? {} : {  display: 'none'  }}>
                        <div className="div-wrapper" onClick={ ()=>{ ShowModal("confirm", {"title":"Deseja cancelar o relatório?", "confirmText":"Confirmar", "cancelText":"Cancelar"}, HandleCancelReport) } }>
                            <div className="text-wrapper-4" >{textLocalization["Step2"]["CancelButton"]}</div>
                        </div>
                        <button className="input-2" onClick={()=>{setReportState(2)}}>
                            <div className="text-wrapper-5" >{textLocalization["Step2"]["NextButton"]}</div>
                            <img
                                className="arrow-right"
                                alt="Arrow right"
                                src={ArrowRightWhite}
                            />
                        </button>
                    </div>
                    <div className="photoPerPageSelect" style={ newTopicName !== "" || !skipText ? {} : {  display: 'none'  }}>
                        <div className="photoPerPageSelectTitle">
                            {textLocalization["Step2"]["ImagesPerPage"]}
                        </div>
                        <div className="photoPerPageSelectContainer">
                            <NumPhotoPage text="1" />
                            <NumPhotoPage text="2" />
                            <NumPhotoPage text="3" />
                            <NumPhotoPage text="4" />
                            <NumPhotoPage text="5" />
                            <NumPhotoPage text="6" />
                        </div>
                    </div>
                </div>
            </div>


            <div className="criar-relatorio" style={  reportState === 2 ?  { display: 'flex' }: { display: 'none'} }>
                <div className="div-2">
                    <div className="section">
                        <div className="menu-sidebar">
                            { !reportFinished ? <div>
                            <div className="text-wrapper-3">
                                {textLocalization["Step3"]["Title"]}
                            </div>
                            <InputToggle
                                name={textLocalization["Step3"]["ReportNameInput"]}
                                elementKey={"name"}
                                maxLength={30}
                                notePencil={NotePencil}
                                arrowbend={ArrowBendDownRight}
                            />

                            <div className="orientacao-folha">
                                <div className="div-wrapper">
                                    <div className="text-wrapper-4">
                                        {textLocalization["Step3"]["PageOrientation"]}
                                    </div>
                                </div>
                                <div className="orientacao-folha-icon">
                                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                    <img
                                        className="options"
                                        alt="Options"
                                        src={
                                            sheetOrientation === "portrait"
                                                ? PagePortraitIconSelected
                                                : PagePortraitIcon
                                        }
                                        onClick={() => {
                                            UpdatePDFConfig(
                                                "sheetOrientation",
                                                "portrait"
                                            );
                                        }}
                                    />
                                    <img
                                        className="options"
                                        alt="Options"
                                        src={
                                            sheetOrientation === "landscape"
                                                ? PageLandscapeIconSelected
                                                : PageLandscapeIcon
                                        }
                                        onClick={() => {
                                            UpdatePDFConfig(
                                                "sheetOrientation",
                                                "landscape"
                                            );
                                        }}
                                    />
                                    </div>
                                     <div className="title-2">
                                    <Switch
                                        className="switch-instance"
                                        size="small"
                                        state="default"
                                        configKey="summary"
                                        toggle
                                    />
                                    <div className="text-wrapper-4">
                                        {textLocalization["Step3"]["Summary"]}
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="orientacao-folha-2">
                                <div className="div-wrapper">
                                    <div className="text-wrapper-4">
                                        {textLocalization["Step3"]["ImagesPerPage"]}
                                    </div>
                                </div>
                                <div className="options-2">
                                    <NumPhotoPage text="1" />
                                    <NumPhotoPage text="2" />
                                    <NumPhotoPage text="3" />
                                    <NumPhotoPage text="4" />
                                    <NumPhotoPage text="5" />
                                    <NumPhotoPage text="6" />
                                </div>
                            </div>
                            <div className="orientacao-folha-2">
                                <div className="div-wrapper">
                                    <p className="text-wrapper-4">
                                        {textLocalization["Step3"]["ImageQuantity"]}
                                    </p>
                                </div>
                                <div className="text-wrapper-5">{`${imgCount} ${textLocalization["Step3"]["ImageQuantityDescription"]}`}</div>
                                <div className="input"onClick={(e)=>{HandleClickAddNewImages(e)}}>
                                    <div className="text-wrapper-6">
                                        {textLocalization["Step3"]["AddPicturesButton"]}
                                    </div>
                                    <img
                                        className="img-2"
                                        alt="Plus"
                                        src={Plus}
                                    />
                                </div>
                                <input
                                    type="file"
                                    name="my_file[]"
                                    style={{display: 'none'}}
                                    ref={UploadNewImagesInput}
                                    multiple
                                ></input>
                                <p className="text-wrapper-7">
                                    {textLocalization["Step3"]["AddPicturesDescription"]}
                                </p>
                            </div>
                            <div className="orientacao-folha-3">
                                <Switch
                                        className="switch-instance"
                                        size="small"
                                        state="default"
                                        configKey="imgCover"
                                        toggle
                                />
                                <div className="text-wrapper-4">
                                        Expandir imagem
                                </div>
                            </div>
                            <div className="orientacao-folha-3">
                                {/* Old summary */}
                                {/* <img className="img" alt="" src={NotePencil} onClick={()=>{setReportState(1)}} /> */}
                                <p onClick={()=>{setReportState(1)}}>Ordenar fotos</p>
                            </div>
                            <div className="orientacao-folha-4">
                                <div className="div-wrapper">
                                    <div className="text-wrapper-4">{textLocalization["Step3"]["LogoTitle"]}</div>
                                </div>
                                <div className="frame">
                                    <div className="text-wrapper-8">
                                        {logoName === "" ? textLocalization["Step3"]["LogoHint"] : logoName}
                                    </div>
                                    <div className="frame-2">
                                        <div className="text-wrapper-9" onClick={(e)=>{HandleLogoInputClick(e)}}>
                                            {textLocalization["Step3"]["LogoButton"]}
                                        </div>
                                    </div>
                                    <input
                                        className='inputAddLogo'
                                        type="file"                                        
                                        ref={UploadLogoInput}                                        
                                    ></input>
                                    {
                                        logoName !== "" ? <img className="btRemoveLogo" alt="" src={CloseIcon} onClick={(e)=>{RemoveLogo();}} /> : null
                                    }
                                </div>
                            </div>                            
                            <InputToggle
                                name={textLocalization["Step3"]["BaseboardTitle"]}
                                elementKey={"baseboard"}
                                maxLength={30}
                                notePencil={NotePencil}
                                arrowbend={ArrowBendDownRight}
                            />
                            <div className="orientacao-folha-5">
                                { finishClicked ? null :
                                <p onClick={()=>{setReportState(1)}}>Voltar</p>
                                }
                            </div>
                            <div className="frame-3">
                                <div className="input" onClick={()=>{
                                        if(!finishClicked){
                                            CallPDFFunction("DownloadPdf"); 
                                            setFinishClicked(true);
                                        }
                                    }}
                                    style={finishClicked ? { marginTop: "16px" } : {}}
                                >
                                    <div className="text-wrapper-6" >
                                        { finishClicked ? "Aguarde..." : textLocalization["Step3"]["FinishButton"]}
                                    </div>
                                    { finishClicked ? null :
                                    <img
                                        className="img-2"
                                        alt="Check"
                                        src={Check}
                                    />
                                    }
                                </div>
                                { finishClicked ? null :
                                <div className="cancel">
                                    <div className="text-wrapper-10" onClick={ ()=>{ ShowModal("confirm", {"title":"Deseja cancelar o relatório?", "confirmText":"Confirmar", "cancelText":"Cancelar"}, HandleCancelReport) } }>
                                        {textLocalization["Step3"]["CancelButton"]}
                                    </div>
                                </div>
                                }
                            </div>
                            </div> : null }

                            { reportFinished ? <div style={{width: "492px"}}>
                                <div className="orientacao-folha-3">
                                    <div className="title-2">
                                        <p style={{fontSize: '20px', fontWeight: 'bold'}}>{reportName}</p>                                    
                                    </div>                                
                                </div>
                                <div className="orientacao-folha-3">
                                    <div className="title-2" style={{overflow: "hidden", textOverflow: "ellipsis"}}>
                                        <p style={{overflow: 'hidden', textOverflow: 'ellipsis'}} onClick={()=>{navigator.clipboard.writeText(reportLink); ClickCopyLink();}}>{reportLink}</p>                                    
                                    </div>
                                    <img className="img" alt="" src={CopyIcon} ref={ClickIconElement} onClick={()=>{navigator.clipboard.writeText(reportLink); ClickCopyLink(); }} />
                                </div>
                                <div className="orientacao-folha-3">
                                    <div className="title-2">
                                        <p>Download</p>                                    
                                    </div>
                                    <a href={reportLink} target='_blank' rel="noopener noreferrer" download>
                                        <img className="img" alt="" src={DownloadIcon}  />
                                    </a>
                                </div>
                                <div className="frame-3" style={{marginTop: '0px'}}>
                                    <div className="input" onClick={()=>{ResetPDFConfig();navigate("/account");}}>
                                        <div className="text-wrapper-6">
                                            Encerrar
                                        </div>                                        
                                    </div>
                                </div>
                            </div> : null }

                        </div>



                        <div className={`preview-section${pageOrientation}`}>
                            <div className={`content${pageOrientation}`}>
                                <PagePreview
                                    logo={Logo}
                                    title={selectedPageTitle}
                                    rodape={""}
                                    pagenumber={selectedPageNumber}
                                />
                                                                
                            </div>



                            <div className={`col-pages${pageOrientation}`}>
                                {miniPages.map((element, index) => {
                                    return (
                                        <PageItemScroll
                                            key={index}
                                            logo={Logo}
                                            index={index}
                                            data={element}
                                        />
                                    );
                                })}
                            </div>
                            <div className={`bt-scroll-container${pageOrientation}`}>
                                <div className="bt-scroll-base">
                                    <img
                                        className={pageOrientation === "" ? "up" : "left"}
                                        alt=""
                                        src={ArrowLineUp}
                                    />
                                </div>
                                <div className="bt-scroll-base">
                                    <img
                                        className={pageOrientation === "" ? "down" : "right"}
                                        alt=""
                                        src={ArrowLineUp}
                                    />
                                </div>
                            </div>


                        </div>
                    </div>
                    <PagePDF
                                    logo={Logo}
                                    title={selectedPageTitle}
                                    rodape={""}
                                    pagenumber={selectedPageNumber}
                    />
                </div>
            </div>
            <EditImageModal />
            <ModalConfirm />
        </div>
    );
}