const LayoutData = {
    showMenu: true,
    loadingScreen: false
}

const LayoutDataObservers = {
    showMenu: [],
    loadingScreen: []
}

export function SetLayoutData(key, value){
    LayoutData[key] = value;
    LayoutDataObservers[key].forEach((callback) => {
        callback(value);
    });
}

export function GetLayoutData(key){
    return LayoutData[key];
}

export function RegisterLayoutDataObserver(key, callback){
    LayoutDataObservers[key].push(callback);
}

export function UnregisterLayoutDataObserver(key, callback){
    LayoutDataObservers[key] = LayoutDataObservers[key].filter((item) => {
        return item !== callback;
    });
}